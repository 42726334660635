import {
  Box,
  Flex,
  Text,
} from '@chakra-ui/react';

import IComponentParams from 'Core/types/IComponentParams';

import Button from '../Button';

export const CtaCardAltParams: IComponentParams[] = [
  {
    name: 'title', type: 'text', placeholder: 'Your', required: true,
  },
  {
    name: 'highlightedTitle', type: 'text', placeholder: 'Title', required: true,
  },
  { name: 'ctaExternal', type: 'boolean' },
  {
    name: 'ctaLink', type: 'text', placeholder: '/', required: true,
  },
  {
    name: 'ctaText', type: 'text', placeholder: 'Link', required: true,
  },
];

interface ICtaCardAlt {
  title: string
  highlightedTitle: string
  ctaExternal: boolean
  ctaLink: string
  ctaText: string
}

export function CtaCardAlt({
  title, highlightedTitle, ctaExternal, ctaLink, ctaText,
}: ICtaCardAlt): JSX.Element {
  return (
    <Flex
      flexDirection="column"
      width="100%"
      p="25px"
      bgColor="white"
      css={{ gap: '8px' }}
    >
      <Box>
        <Text
          fontSize="20px"
          lineHeight="24px"
          fontWeight="bold"
          color="secondary.500"
          mb="12px"
        >
          {title}
          {' '}
          <Text color="primary.500" as="span">
            {highlightedTitle}
          </Text>
        </Text>
      </Box>
      <Flex flexWrap="wrap" pl="6px" css={{ gap: '12px' }}>
        <Button
          colorScheme="secondary"
          variant="outline"
          withIcon
          target={ctaExternal ? '_blank' : '_self'}
          link={ctaLink}
        >
          {ctaText}
        </Button>
      </Flex>
    </Flex>
  );
}
