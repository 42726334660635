import IComponentParams from './IComponentParams';
import ILine from './ILine';
import ILineComponent, { DEFAULT_VALUES as LC_DEFAULT_VALUES } from './ILineComponent';
import IPage, { DEFAULT_VALUES as PAGE_DEFAULT_VALUES } from './IPage';

interface IBuilderContext {
  page: IPage;
  setPage: (page: IPage) => void;
  lines: ILine[];
  setLines: (lines: ILine[]) => void;
  addComponent: (
    lineId: string,
    column: number,
    component: string,
    data: unknown,
    params: IComponentParams[]
  ) => void;
  addLine: (columns: number) => void;
  removeLine: (id: string) => void;
  deleteOnOpen: () => void;
  setDeleteId: (id: string) => void;
  updateComponent: (lineId: string, componentId: string, data: unknown) => void;
  updateOnOpen: () => void;
  updateIsOpen: boolean;
  updateOnClose: () => void;
  openUpdate: (lineId: string, componentId: string) => void;
  componentToUpdate: ILineComponent | undefined;
  updating: boolean;
  deleting: boolean;
  openUpdateLine: (id: string, columns: number) => void,
  deleteProjectOnOpen: () => void,
  sendToReview: () => void,
  isVisualization: boolean,
  setIsVisualization: (value: boolean) => void
}

export const DEFAULT_VALUES = {
  page: PAGE_DEFAULT_VALUES,
  setPage: (): void => undefined,
  lines: [],
  setLines: (): void => undefined,
  addComponent: (): void => undefined,
  addLine: (): void => undefined,
  removeLine: (): void => undefined,
  deleteOnOpen: (): void => undefined,
  setDeleteId: (): void => undefined,
  updateComponent: (): void => undefined,
  updateOnOpen: (): void => undefined,
  updateIsOpen: false,
  updateOnClose: (): void => undefined,
  openUpdate: (): void => undefined,
  componentToUpdate: LC_DEFAULT_VALUES,
  updating: false,
  deleting: false,
  openUpdateLine: (): void => undefined,
  deleteProjectOnOpen: (): void => undefined,
  sendToReview: (): void => undefined,
  isVisualization: true,
  setIsVisualization: (): void => undefined,
};

export default IBuilderContext;
