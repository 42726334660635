import { Box } from '@chakra-ui/react';

import IComponentParams from 'Core/types/IComponentParams';

interface IFinalNotes { content: string }
export const FinalNotesParams: IComponentParams[] = [
  {
    name: 'content',
    type: 'text',
    placeholder: 'Your title',
    required: true,
  },
];

export function FinalNotes({ content }: IFinalNotes): JSX.Element {
  return (
    <Box borderTop="2px solid #1A2447" pt="20px">
      {content}
    </Box>
  );
}

export default FinalNotes;
