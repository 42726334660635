import { te } from './template-engine';

const week = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const weekAbbr = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const monthAbbr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const messages = {
  relative: {
    past: 'Updated {time} ago',
    seconds: 'Updated a few seconds ago',
    minute: 'Updated a minute ago',
    minutes: 'Updated {time} minutes ago',
    hour: 'Updated a hour ago',
    hours: 'Updated {time} hours ago',
    day: 'Updated a day ago',
    days: 'Updated {time} days ago',
    mounth: 'Updated a mounth ago',
    mounths: 'Updated {time} months ago',
    year: 'Updated a year ago',
    years: 'Updated {time} years ago',
  },
};

const Util = {
  today: new Date(),
  format: (date: string | Date, format: string): string => {
    const d = String(new Date(date).getDate());
    const dd = String(new Date(date).getDate()).padStart(2, '0');

    const w = weekAbbr[new Date(date).getDay()];
    const ww = week[new Date(date).getDay()];

    const m = String(new Date(date).getMonth() + 1);
    const mm = String(new Date(date).getMonth() + 1).padStart(2, '0');
    const mmm = monthAbbr[new Date(date).getMonth()];
    const mmmm = months[new Date(date).getMonth()];

    const yy = String(new Date(date).getFullYear()).slice(-2);
    const yyyy = String(new Date(date).getFullYear());

    return te(format, {
      d, dd, w, ww, m, mm, mmm, mmmm, yy, yyyy,
    });
  },
  relative: (date: string | Date): string => {
    const actualDate = new Date();
    const oldDate = new Date(date);

    const actualTimestamp = actualDate.getTime();
    const oldTimestamp = oldDate.getTime();

    const actualInSeconds = Math.floor(actualTimestamp / 1000);
    const oldInSeconds = Math.floor(oldTimestamp / 1000);

    const difference = actualInSeconds - oldInSeconds;

    if (difference < 60) {
      return te(messages.relative.seconds, {});
    } if (difference < 3600) {
      const time = Math.floor(difference / 60);
      return time <= 1
        ? te(messages.relative.minute, {})
        : te(messages.relative.minutes, { time: String(time) });
    } if (difference < 86400) {
      const time = Math.floor(difference / 3600);
      return time <= 1
        ? te(messages.relative.hour, {})
        : te(messages.relative.hours, { time: String(time) });
    } if (difference < 2620800) {
      const time = Math.floor(difference / 86400);
      return time <= 1
        ? te(messages.relative.day, {})
        : te(messages.relative.days, { time: String(time) });
    } if (difference < 31449600) {
      const time = Math.floor(difference / 2620800);
      return time <= 1
        ? te(messages.relative.mounth, {})
        : te(messages.relative.mounths, { time: String(time) });
    } if (difference >= 2620800) {
      const time = Math.floor(difference / 31449600);
      return time <= 1
        ? te(messages.relative.year, {})
        : te(messages.relative.years, { time: String(time) });
    }

    return 'undefined';
  },
};

export default Util;
