import ILine, { DEFAULT_VALUES as LINE_DEFAULT_VALUES } from './ILine';

interface ILineContext {
  line: ILine;
}

export const DEFAULT_VALUES = {
  line: LINE_DEFAULT_VALUES,
};

export default ILineContext;
