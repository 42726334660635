import { Icon, IconProps } from '@chakra-ui/react';

function VideoTranscript(props: IconProps): JSX.Element {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" {...props}>
      <g transform="translate(-467 -1583)">
        <g transform="translate(469 1585)" fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="2">
          <circle cx="26" cy="26" r="26" stroke="none" />
          <circle cx="26" cy="26" r="27" fill="none" />
        </g>
        <g transform="translate(481.651 1602.749)">
          <path
            d="M25.968,10.5H5.942A3.448,3.448,0,0,0,2.5,13.942V23.955A3.448,3.448,0,0,0,5.942,27.4H25.968a3.448,3.448,0,0,0,3.442-3.442V13.942A3.448,3.448,0,0,0,25.968,10.5Zm1.565,7.2v6.258a1.565,1.565,0,0,1-1.565,1.565H5.942a1.565,1.565,0,0,1-1.565-1.565V13.942a1.565,1.565,0,0,1,1.565-1.565H25.968a1.565,1.565,0,0,1,1.565,1.565Z"
            transform="translate(-2.5 -10.5)"
            fill="currentColor"
          />
          <path
            d="M27.368,20.356,19.858,16.6a.941.941,0,0,0-1.358.839v7.51a.951.951,0,0,0,.444.8.922.922,0,0,0,.494.138.9.9,0,0,0,.419-.1l7.51-3.755a.937.937,0,0,0,0-1.677Zm-6.99,3.073V18.954l4.475,2.24Z"
            transform="translate(-8.487 -12.746)"
            fill="currentColor"
          />
        </g>
      </g>
    </Icon>

  );
}

export default VideoTranscript;
