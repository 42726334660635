import { useContext } from 'react';

import { ComponentConfigContext } from 'Core/context/ComponentConfigProvider';
import IComponenteConfigContext from 'Core/types/IComponentConfigContext';

const useComponentConfig = function (): IComponenteConfigContext {
  const context = useContext(ComponentConfigContext);

  return context;
};

export default useComponentConfig;
