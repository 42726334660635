import {
  Box,
  Button,
  Link,
  Text,
  Stack,
  Flex,
  Heading,
  IconButton,
} from '@chakra-ui/react';
import { AiOutlineEye } from 'react-icons/ai';
import { FaPlay, FaRegCalendar } from 'react-icons/fa';
import { MdPlayCircleOutline } from 'react-icons/md';
import { VscEye } from 'react-icons/vsc';

import FlagPlaceholder from 'assets/images/FlagPlaceholder.webp';
import dateFormatter from 'Core/helpers/dateFormatter.js';
import IComponentParams from 'Core/types/IComponentParams';

export const VideoCardParams: IComponentParams[] = [
  {
    name: 'title',
    type: 'text',
    placeholder: 'Your title',
    required: true,
  },
  {
    name: 'link',
    type: 'text',
    placeholder: 'Video URL',
    required: true,
  },
  { name: 'image', type: 'image' },
];

interface IVideoCard {
  title: string,
  image: string,
  link: string,
  views?: number,
  target?: string,
  minified?: boolean,
  withPlayIcon?: boolean,
  bg?: string,
  shadow?: string,
  isAudio?: boolean,
  onlyTitle?: boolean,
  variant?: string,
  date?: string
}

export const VideoCard: React.FC<IVideoCard> = function ({
  title,
  image,
  views,
  link,
  target,
  minified,
  withPlayIcon,
  bg,
  shadow,
  isAudio,
  onlyTitle,
  variant = 'normal',
  date,
}) {
  const isMobile = false;
  const variants = {
    normal: {
      position: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      },
      image: {
        width: '100%',
        height: null,
        pt: '56%',
      },
      withIcon: {
        bg: 'linear-gradient(133deg, rgba(24,21,21,1) 0%, rgba(85,88,98,1) 100%)',
        _hover: {
          bg: 'linear-gradient(133deg, rgba(24,21,21,1) 0%, rgba(85,88,98,1) 100%)',
        },
      },
      info: {
        p: { base: '10px 5px', lg: '24px' },
      },
      title: {
        display: '-webkit-box',
        noOfLines: '2',
        overflow: 'hidden',
      },
    },
    inline: {
      base: {
        display: {
          base: 'flex',
          lg: 'initial',
        },
        flexDirection: {
          base: 'column',
          sm: 'row',
        },
        alignItems: 'center',
      },
      position: {
        top: {
          base: 0,
          sm: 'initial',
          lg: 0,
        },
        left: {
          base: 0,
          sm: 'initial',
          lg: 0,
        },
        right: 0,
        bottom: 0,
      },
      image: {
        width: {
          base: '100%',
          sm: '174px',
          md: '312px',
          lg: '100%',
        },
        height: {
          base: 'initial',
          sm: '96px',
          md: '172px',
          lg: 'initial',
        },
        pt: {
          base: '56%',
          sm: '0',
          lg: '56%',
        },
        flexShrink: '0',
        flexGrow: '0',
      },
      withIcon: {
        bg: 'linear-gradient(133deg, rgba(109,0,30,1) 0%, rgba(184,0,21,1) 100%)',
        _hover: {
          bg: 'linear-gradient(133deg, rgba(109,0,30,1) 0%, rgba(184,0,21,1) 100%)',
        },
      },
      info: {
        width: '100%',
        p: { base: '10px 5px', sm: '0 32px', lg: '24px' },
      },
      title: {
        display: '-webkit-box',
        noOfLines: '2',
        overflow: 'hidden',
      },
    },
  };
  return (
    <Box
      bg={{ base: 'transparent', lg: minified ? 'transparent' : bg || 'white' }}
      boxShadow={{
        base: 'transparent',
        lg: minified ? null : shadow || '0px 10px 20px rgba(0,0,0,.04)',
      }}
      {...variants[variant].base}
    >
      <Box
        bgImage={image || FlagPlaceholder}
        bgSize="cover"
        bgPos="center"
        bgRepeat="no-repeat"
        position="relative"
        borderRadius={{ base: '8px', lg: '0px' }}
        _hover={{ opacity: withPlayIcon ? 0.8 : 1 }}
        transition="opacity .3s"
        {...variants[variant].image}
      >
        {withPlayIcon || isMobile ? (
          <Link
            href={link}
            target={target}
            position="absolute"
            _focus={{
              outline: 'none',
            }}
            {...variants[variant].position}
          >
            <IconButton
              position="absolute"
              borderRadius="100%"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              transition="0.3s"
              icon={<FaPlay color="white" size="12px" />}
              {...variants[variant].withIcon}
            />
          </Link>
        ) : null}
      </Box>
      <Box {...variants[variant].info}>
        <Flex alignItems="center" justifyContent="space-between">
          <Box width="100%" mr="16px">
            <Heading
              wordBreak="break-word"
              width="100%"
              mb="4px"
              color="secondary.500"
              fontWeight="700"
              fontSize="16px"
              as="h2"
              {...variants[variant].title}
            >
              {title}
            </Heading>
            <Stack
              direction="row"
              spacing="16px"
              alignItems="center"
              fontWeight="500"
            >
              {!onlyTitle && views ? (
                <Stack
                  direction="row"
                  spacing="4px"
                  color="primary.500"
                  alignItems="center"
                >
                  {isAudio ? (
                    <VscEye size="18px" />
                  ) : (
                    <AiOutlineEye size="18px" />
                  )}
                  <Text fontSize="12px">{views}</Text>
                </Stack>
              ) : null}
              {!onlyTitle && date ? (
                <Stack
                  direction="row"
                  spacing="8px"
                  color="secondary.500"
                  alignItems="center"
                >
                  <FaRegCalendar size="14px" />
                  <Text fontSize="12px">{dateFormatter(date)}</Text>
                </Stack>
              ) : null}
            </Stack>
          </Box>
          {!minified ? (
            <Link
              href={link}
              target={target}
              _hover={{ textDecor: 'none' }}
              display={{ base: 'none', lg: 'initial' }}
            >
              <Button
                rightIcon={<MdPlayCircleOutline size="18px" />}
                colorScheme="primary"
                fontSize="12px"
                height="50px"
                borderRadius="10px"
                _focus={{
                  outline: 'none !important',
                }}
              >
                {isAudio ? 'Listen Now' : 'Watch Now'}
              </Button>
            </Link>
          ) : null}
        </Flex>
      </Box>
    </Box>
  );
};
