import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Stack,
  ModalFooter,
  Button,
  Box,
  Text,
  useToast,
  Flex,
} from '@chakra-ui/react';
import React, {
  useEffect,
  useState,
  useCallback,
} from 'react';

import { AfldsComponents } from 'Components/aflds';
import ComponentParamInput from 'Components/elements/ComponentParamInput';
import useBuilder from 'Core/hooks/use-builder';
import useComponentConfig from 'Core/hooks/use-component-config';
import useLine from 'Core/hooks/use-line';
import IComponentParams from 'Core/types/IComponentParams';

const CustomInputs: React.FC<{
  params: IComponentParams[];
  updateValue: (
    name: string,
    value: string | boolean | React.ReactElement
  ) => void;
}> = function ({ params, updateValue }) {
  return (
    <Stack spacing="16px">
      {params.map((param) => (
        <Box key={param.name}>
          <ComponentParamInput param={param} updateValue={updateValue} />
        </Box>
      ))}
    </Stack>
  );
};

const ComponentConfigModal: React.FC<{ isUpdate?: boolean }> = function ({
  isUpdate,
}) {
  const {
    component, configIsOpen, configOnClose, activeColumn,
  } = useComponentConfig();
  const { addComponent, updateComponent } = useBuilder();
  const { line } = useLine();

  const [values, setValues] = useState<{
    [key: string]: string | boolean | React.ReactElement;
  }>({});
  const [defaultValues, setDefaultValues] = useState<{
    [key: string]: string | boolean | React.ReactElement;
  }>({});

  const toast = useToast();

  const [test, setTest] = useState(false);

  const updateValue = useCallback(
    (name: string, value: string | boolean | React.ReactElement): void => {
      const valuesAux = {};
      Object.assign(valuesAux, values);
      valuesAux[name] = value;
      setValues(valuesAux);
    },
    [values],
  );

  const checkIsEmpty = (name: string): boolean => {
    const array = [undefined, null, ''];
    return array.includes(values[name] as string);
  };

  const submit = (): void => {
    for (let i = 0; i < component.params.length; i += 1) {
      const param = component.params[i];

      if (param.required && checkIsEmpty(param.name)) {
        toast({
          title: 'Info missing',
          description: 'Please check all required fields (*)',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });

        return;
      }
    }
    if (isUpdate) updateComponent(line._id, component.name, values);
    else {
      addComponent(
        line._id,
        activeColumn,
        component.name,
        values,
        component.params,
      );
    }
    configOnClose();
  };

  useEffect(() => {
    if (!configIsOpen) {
      setValues({});
      return;
    }

    const valuesAux: { [key: string]: string } = {};
    component.params.map((param): IComponentParams => {
      if (['text', 'textarea'].includes(param.type)) {
        valuesAux[param.name] = param.placeholder || param.label || param.name || '';
      }

      return param;
    });
    setDefaultValues(valuesAux);
  }, [configIsOpen, component.params]);

  return (
    <Modal isOpen={configIsOpen} onClose={configOnClose}>
      <ModalOverlay />
      <ModalContent maxW={{ base: '100%', lg: '70%' }}>
        <ModalHeader>{component.params.length > 0 ? 'Component data' : 'Component Preview'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody maxH="70vh" position="relative" overflow="auto">
          <Flex flexDir={{ base: 'column', lg: 'row' }} gap="24px">
            {component.params.length > 0 ? (
              <Box w={{
                base: '100%',
                lg: '50%',
              }}
              >
                <CustomInputs params={component.params} updateValue={updateValue} />
              </Box>

            ) : null}
            <Box
              w={{
                base: '100%',
                lg: '50%',
              }}
              flexShrink={0}
              flexGrow={0}
              position="relative"
            >
              <Box position="sticky" top="24px">
                {component.params.length > 0 ? <Text ml="24px" fontWeight="bold">Component Preview</Text> : null}
                <AfldsComponents
                  component={component.name}
                  data={{ ...defaultValues, ...values }}
                  componentId={component.name}
                  preview
                />
              </Box>
            </Box>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="gray" mr="auto" onClick={() => setTest(!test)}>
            Cancel
          </Button>
          <Button colorScheme="blue" onClick={submit}>
            Add component
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

ComponentConfigModal.defaultProps = {
  isUpdate: false,
};

export default ComponentConfigModal;
