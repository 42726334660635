import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  fonts: {
    body: 'Montserrat, sans-serif',
    heading: 'Montserrat, sans-serif',
    mono: 'Montserrat, sans-serif',
  },
  colors: {
    primary: {
      100: '#D1899D',
      200: '#C1627C',
      300: '#AC3A59',
      400: '#881333',
      500: '#6D001E',
      600: '#5C0019',
      700: '#480014',
      800: '#390010',
      900: '#2F000E',
    },
    secondary: {
      100: '#727FAA',
      200: '#52608E',
      300: '#354169',
      400: '#263157',
      500: '#1A2447',
      600: '#131D40',
      700: '#0A1434',
      800: '#050D27',
      900: '#01081D',
    },
    blue: {
      100: '#C4D5FF',
      200: '#AAC2FF',
      300: '#7D9FF7',
      400: '#547DE6',
      500: '#285DE6',
      600: '#1F4EC7',
      700: '#1D47B4',
      800: '#173990',
      900: '#0D286E',
    },
  },
  styles: {
    global: {
      body: {
        bg: '#F3F2F8',
        fontFamily: 'Montserrat, sans-serif',
        color: '#3B3B3B',
      },
    },
  },
  components: {
    Button: {
      baseStyle: {
        borderRadius: '5px',
        fontFamily: 'Montserrat, sans-serif',
      },
    },
  },
});

export default theme;
