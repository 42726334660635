import {
  FormControl,
  FormLabel,
  Input,
  Text,
  Checkbox,
  Select,
  Textarea,
  Box,
  Grid,
  IconButton,
  HStack,
  useToast,
  Button,
} from '@chakra-ui/react';
import React, {
  ReactFragment,
  useEffect,
  useState,
} from 'react';
import * as Icons from 'react-icons/fa';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

import axios from 'axios';
import * as AFLDSIcons from 'Components/aflds/Icons';
import capitalizeFirstLetter from 'Core/helpers/capitalizeFirstLetter';
import IComponentParams from 'Core/types/IComponentParams';

const AFLDSIconSelect: React.FC<{
  selectIcon: (name: string, icon: React.ReactElement | string) => void;
  paramName: string;
}> = function ({ selectIcon, paramName }) {
  const [icons, setIcons] = useState<
    { name: string; element: React.ReactElement }[]
  >([]);

  useEffect(() => {
    const aux: { name: string; element: React.ReactElement }[] = [];
    Object.entries(AFLDSIcons).forEach(([name, Icon]) => {
      aux.push({ name, element: <Icon width="32px" height="32px" color="#1a2447" /> });
    });
    setIcons(aux);
  }, []);

  return (
    <Box pr="8px">
      <Grid
        templateColumns={{ base: 'repeat(4, 1fr)', lg: 'repeat(7, 1fr)' }}
        gap="8px"
      >
        {icons.map((icon) => (
          <IconButton
            key={icon.name}
            icon={icon.element}
            aria-label="icon"
            onClick={() => selectIcon(paramName, icon.name)}
          />
        ))}
      </Grid>
    </Box>
  );
};

const IconSelect: React.FC<{
  selectIcon: (name: string, icon: React.ReactElement | string) => void;
  paramName: string;
}> = function ({ selectIcon, paramName }) {
  const [icons, setIcons] = useState<
    { name: string; element: React.ReactElement }[]
  >([]);

  const [page, setPage] = useState(0);

  useEffect(() => {
    const aux: { name: string; element: React.ReactElement }[] = [];
    Object.entries(Icons).forEach(([name, Icon]) => {
      aux.push({ name, element: <Icon size="30px" /> });
    });
    setIcons(aux);
  }, []);

  return (
    <Box maxH="300px" overflowY="auto" pr="8px">
      <Grid
        templateColumns={{ base: 'repeat(4, 1fr)', lg: 'repeat(7, 1fr)' }}
        gap="8px"
      >
        {icons.slice(28 * page, 28 * page + 28).map((icon) => (
          <IconButton
            key={icon.name}
            icon={icon.element}
            aria-label="icon"
            onClick={() => selectIcon(paramName, icon.name)}
          />
        ))}
      </Grid>

      <HStack spacing="8px" justifyContent="center" mt="24px">
        <IconButton
          aria-label="back"
          onClick={() => setPage(page - 1)}
          icon={<FaChevronLeft />}
          size="sm"
        />
        <Text>
          {page + 1}
          {' '}
          /
          {Math.floor(icons.length / 28)
            + (icons.length % 28) / (icons.length % 28)}
        </Text>
        <IconButton
          aria-label="next"
          onClick={() => setPage(page + 1)}
          icon={<FaChevronRight />}
          size="sm"
        />
      </HStack>
    </Box>
  );
};

const input = (
  param: IComponentParams,
  updateValue: (
    name: string,
    value: string | boolean | React.ReactElement
  ) => void,
  submiting: () => void,
  defaultValue?: string | boolean | React.ReactElement,
): ReactFragment => {
  const uploadImage = async (file: unknown): Promise<void> => {
    if (!file) return;

    submiting();
    const formData = new FormData();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    formData.append('file', file);
    formData.append('upload_preset', 'or4spvga');
    formData.append('api_key', process.env.REACT_APP_CLOUDINARY_API_KEY);
    const { data } = await axios.post(
      `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`,
      formData,
    );

    updateValue(param.name, data.url);
  };

  const types: { [key: string]: ReactFragment } = {
    text: (
      <Input
        defaultValue={defaultValue as string}
        onChange={(e) => updateValue(param.name, e.target.value)}
      />
    ),
    date: (
      <Input
        type="date"
        defaultValue={defaultValue as string}
        onChange={(e) => updateValue(param.name, e.target.value)}
      />
    ),
    textarea: (
      <Textarea
        defaultValue={defaultValue as string}
        onChange={(e) => updateValue(param.name, e.target.value)}
      />
    ),
    image: (
      <Box>
        <input
          type="file"
          onChange={(e) => {
            uploadImage(e.target.files[0]);
          }}
        />
        <Button
          mt="8px"
          size="xs"
          variant="ghost"
          colorScheme="red"
          onClick={() => {
            updateValue(param.name, null);
          }}
        >
          Clear image
        </Button>
      </Box>
    ),
    boolean: (
      <Checkbox onChange={(e) => updateValue(param.name, e.target.checked)}>
        Yes
      </Checkbox>
    ),
    select: (
      <Select
        placeholder="Select option"
        onChange={(e) => updateValue(param.name, e.target.value)}
      >
        {param.options?.map((option) => (
          <option value={option.value} selected={option.value === defaultValue} key={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
    ),
    icon: <IconSelect selectIcon={updateValue} paramName={param.name} />,
    aflds_icon: <AFLDSIconSelect selectIcon={updateValue} paramName={param.name} />,
  };

  return types[param.type] || <Text>Invalid</Text>;
};

interface IComponentParamInput {
  param: IComponentParams;
  updateValue: (
    name: string,
    value: string | boolean | React.ReactElement
  ) => void;
  defaultValue?: string | boolean | React.ReactElement;
}

const ComponentParamInput: React.FC<IComponentParamInput> = function ({
  param,
  updateValue,
  defaultValue,
}) {
  const toast = useToast();

  const submiting = (): void => {
    toast({
      status: 'info',
      title: 'Uploading in progress...',
      duration: 2000,
    });
  };

  return (
    <FormControl key={param.name} isRequired={param.required}>
      <FormLabel>
        {capitalizeFirstLetter(
          param.label || param.name.replace(/([A-Z])/g, ' $1').trim(),
        )}
      </FormLabel>
      {input(param, updateValue, submiting, defaultValue)}
    </FormControl>
  );
};

ComponentParamInput.defaultProps = {
  defaultValue: undefined,
};

export default ComponentParamInput;
