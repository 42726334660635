import { Icon, IconProps } from '@chakra-ui/react';

function Faqs(props: IconProps): JSX.Element {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 63" {...props}>
      <g transform="translate(-420 -3011)">
        <g transform="translate(422 3013)" fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="2">
          <circle cx="29.5" cy="29.5" r="29.5" stroke="none" />
          <circle cx="29.5" cy="29.5" r="30.5" fill="none" />
        </g>
        <g transform="translate(435.119 3024.861)">
          <path
            d="M-2327.625,2539.17c0,1.056.009,1.908,0,2.76a2.89,2.89,0,0,1-3.06,3.051c-3.2.011-6.394.015-9.591-.005a2.063,2.063,0,0,0-1.429.487q-2.431,2-4.92,3.931a1.087,1.087,0,0,1-.865.254c-.2-.091-.316-.536-.326-.831-.037-1.1-.026-2.195-.009-3.292.006-.4-.13-.568-.537-.544-.38.022-.763.007-1.145,0a2.922,2.922,0,0,1-3.11-3.089c-.033-1.861-.01-3.722-.01-5.583q0-3.472,0-6.944c0-2.255,1.148-3.406,3.383-3.408s4.486,0,6.728.007c.336,0,.648.052.716-.452.558-4.093,2.986-6.7,6.681-8.237a12.173,12.173,0,0,1,13.313,2.847,9.4,9.4,0,0,1-1.6,14.217,1.369,1.369,0,0,0-.649,1.291c.041,1.645.026,3.292,0,4.938,0,.254-.121.662-.286.721a1.043,1.043,0,0,1-.819-.186C-2325.949,2540.535-2326.7,2539.908-2327.625,2539.17Zm2.453.483c0-1.606.039-3.012-.016-4.414a1.656,1.656,0,0,1,.842-1.633,8.336,8.336,0,0,0,.121-14.09,11.149,11.149,0,0,0-13.656.781,8.185,8.185,0,0,0-.669,11.781,10.727,10.727,0,0,0,7.474,3.448,2.67,2.67,0,0,1,1.334.508C-2328.246,2537.166-2326.795,2538.36-2325.173,2539.653Zm-21.533,8.321c.318-.236.508-.369.688-.513,1.323-1.055,2.628-2.135,3.978-3.155a2.2,2.2,0,0,1,1.217-.428c3.362-.034,6.725-.014,10.086-.024a1.816,1.816,0,0,0,1.981-1.947c.014-1.049.007-2.1,0-3.147,0-.184.008-.46-.1-.535-.847-.6-1.424-1.6-2.657-1.628a8.712,8.712,0,0,1-2.249-.38c-4.383-1.3-7.323-3.967-8.04-8.668-.076-.5-.374-.466-.709-.466-2.289,0-4.579,0-6.868.006a1.864,1.864,0,0,0-2.1,2.059c-.019,1.121-.005,2.242-.005,3.362q0,4.507,0,9.014c0,1.719.621,2.334,2.318,2.336.525,0,1.05.021,1.574-.005.642-.032.91.235.891.885C-2346.727,2545.761-2346.706,2546.785-2346.706,2547.974Z"
            transform="translate(2352.637 -2516.398)"
            fill="currentColor"
          />
          <path
            d="M-2220.235,2545.965a3.054,3.054,0,0,0-2.758-3.27,2.986,2.986,0,0,0-3.217,2.6c-.016.118.021.273-.042.349-.033.039-.066.078-.1.115a.586.586,0,0,1-.972-.176.5.5,0,0,1-.019-.065,2.018,2.018,0,0,1,.108-.911,4.125,4.125,0,0,1,3.866-3.063,4.134,4.134,0,0,1,4.062,2.8,4.021,4.021,0,0,1-1.511,4.685,3.554,3.554,0,0,0-1.876,2.923.271.271,0,0,1-.09.164.6.6,0,0,1-.918-.093.346.346,0,0,1-.063-.187,3.91,3.91,0,0,1,1.966-3.5A2.9,2.9,0,0,0-2220.235,2545.965Z"
            transform="translate(2245.301 -2537.943)"
            fill="currentColor"
          />
          <path
            d="M-2317.593,2674.85h8.509c.1,0,.22-.037.281.009s.127.1.188.152a.543.543,0,0,1-.122.915.366.366,0,0,1-.1.026c-.829.066-1.667.028-2.5.028q-7.366,0-14.731,0c-.213,0-.473.055-.629-.045a.872.872,0,0,1-.392-.578c-.008-.152.242-.379.427-.465a1.586,1.586,0,0,1,.635-.037Q-2321.812,2674.849-2317.593,2674.85Z"
            transform="translate(2330.751 -2652.153)"
            fill="currentColor"
          />
          <path
            d="M-2320.692,2649.932q2.792,0,5.584,0c.424,0,.886,0,.879.568s-.471.561-.893.561q-5.619,0-11.24,0c-.424,0-.881,0-.877-.574s.476-.554.891-.554Z"
            transform="translate(2330.875 -2630.814)"
            fill="currentColor"
          />
          <path
            d="M-2322.978,2624.929c1.1,0,2.191,0,3.286,0,.4,0,.742.09.739.562s-.344.567-.743.566c-2.262,0-4.525.014-6.786-.025a.9.9,0,0,1-.678-.516c-.1-.449.272-.587.682-.586C-2325.312,2624.932-2324.145,2624.93-2322.978,2624.929Z"
            transform="translate(2330.822 -2609.389)"
            fill="currentColor"
          />
          <circle
            cx="0.573"
            cy="0.573"
            r="0.573"
            transform="translate(21.423 15.405)"
            fill="currentColor"
          />
        </g>
      </g>
    </Icon>

  );
}

export default Faqs;
