import { useMediaQuery } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import type IComponentParams from 'Core/types/IComponentParams';
import Date from 'Core/util/date';

import Desktop from './desktop';
import Mobile from './mobile';

export const MinimalistPostCardParams: IComponentParams[] = [
  {
    name: 'newsSlug',
    type: 'text',
    placeholder: 'Article slug',
    required: true,
  },
  {
    name: 'title',
    type: 'text',
    placeholder: 'Your Title',
    required: true,
  },
  {
    name: 'date',
    type: 'date',
    placeholder: `${Date.today}`,
    required: true,
  },
];

interface INews {
  newsSlug: string
  title: string
  date: string | Date
}

export function MinimalistPostCard(props: INews): JSX.Element {
  const [isMobile, setMobile] = useState(false);
  const [isLessThanTablet] = useMediaQuery('(max-width: 1280px)');

  useEffect(() => {
    setMobile(isLessThanTablet);
  }, [isLessThanTablet]);

  return isMobile ? <Mobile {...props} /> : <Desktop {...props} />;
}
