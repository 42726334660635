import {
  Box,
  Heading,
  Text,
} from '@chakra-ui/react';

import IComponentParams from 'Core/types/IComponentParams';

export const MinimalistContentParams: IComponentParams[] = [
  {
    name: 'title',
    type: 'text',
    placeholder: 'Your title',
    required: true,
  },
  {
    name: 'content',
    type: 'textarea',
    placeholder: 'You can write anything',
    required: true,
  },
];

interface IMinimalistContent {
  title: string
  content: string
}

export function MinimalistContent({ title, content }: IMinimalistContent): JSX.Element {
  return (
    <Box maxW="100%" pos="relative">
      <Heading mb="8px" color="primary.500" fontSize="16px" as="h2">
        {title}
      </Heading>
      <Text wordBreak="break-word" d="inline-block">
        {content}
      </Text>
    </Box>
  );
}

export default MinimalistContent;
