import {
  Flex,
  Stack,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { MdOutlinePhotoSizeSelectSmall } from 'react-icons/md';

import useComponentConfig from 'Core/hooks/use-component-config';
import IComponent from 'Core/types/IComponent';

const ComponentButton: React.FC<{ component: IComponent }> = function ({
  component,
}) {
  const { openComponentConfig } = useComponentConfig();

  return (
    <Flex
      color="blue.500"
      bgColor="white"
      transition="0.3s"
      _hover={{
        bgColor: '#E2E6FF',
        transform: 'translateY(-2px)',
      }}
      boxShadow="0 3px 15px rgba(220, 228, 245, .4)"
      flexDir="column"
      alignItems="center"
      size="xl"
      pt="100%"
      pos="relative"
      borderRadius="15px"
      cursor="pointer"
      onClick={() => openComponentConfig(component)}
    >
      <Stack
        spacing="16px"
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        alignItems="center"
      >
        <MdOutlinePhotoSizeSelectSmall size="35px" />
        <Text fontWeight="500" color="#3B3B3B" fontSize="14px">
          {component.name}
        </Text>
      </Stack>
    </Flex>
  );
};

export default ComponentButton;
