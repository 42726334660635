import { Icon, IconProps } from '@chakra-ui/react';

function Mask(props: IconProps): JSX.Element {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94 94" {...props}>
      <g transform="translate(-461 -430)">
        <g transform="translate(471.249 466.831)">
          <path
            d="M56.242,82.736c-4.255.307-8.573-.93-12.747-2.679-1.952-.817-3.8-1.873-5.674-2.848a1.735,1.735,0,0,1-1.029-1.43,1.257,1.257,0,0,0-.614-.762c-.9-.53-1.85-.989-2.773-1.486a27.4,27.4,0,0,1-9.3-7.772A13.229,13.229,0,0,1,21.429,59.6a5.368,5.368,0,0,1,.463-3.238A5.076,5.076,0,0,1,26.411,53.7a13.544,13.544,0,0,1,6.706,1.539c1.375.63,2.723,1.315,4.1,1.946a1.384,1.384,0,0,0,.84.09c4.755-1.166,9.524-2.288,14.251-3.558a17.488,17.488,0,0,1,9.025.152c4.64,1.169,9.3,2.283,13.952,3.4a1.508,1.508,0,0,0,.937-.125,38.776,38.776,0,0,1,6.624-2.928,10.581,10.581,0,0,1,4.734-.433,5.009,5.009,0,0,1,4.339,5.783,13.2,13.2,0,0,1-3.459,7.119A30.391,30.391,0,0,1,78.5,74.307c-.446.219-.878.469-1.3.724a1.427,1.427,0,0,0-.694,1.031,1.541,1.541,0,0,1-.651.862,37.455,37.455,0,0,1-9.586,4.494A35.6,35.6,0,0,1,56.242,82.736ZM38.794,58.713c.228,2.128.514,4.143.645,6.167a48.524,48.524,0,0,1-.688,10.763c-.048.3-.023.482.3.63,1.7.768,3.374,1.582,5.076,2.341a31.721,31.721,0,0,0,16.46,2.393,30.5,30.5,0,0,0,10.3-3.189c1.042-.51,2.044-1.092,3.065-1.643a.9.9,0,0,0,.5-1.14,31.443,31.443,0,0,1-.779-9.351c.163-2.057.5-4.1.8-6.15a.517.517,0,0,0-.368-.662c-.451-.164-.9-.342-1.365-.464-3.966-1.036-7.955-1.989-11.894-3.109a15.535,15.535,0,0,0-8.358.013c-3.465.9-6.943,1.754-10.419,2.621C40.96,58.212,39.842,58.466,38.794,58.713ZM75.226,68.3c.11,1.456.191,2.915.343,4.366.085.8.339.908,1.118.564.557-.246,1.1-.514,1.643-.794A26.608,26.608,0,0,0,88.4,63.865a9.261,9.261,0,0,0,1.822-4.6,3.4,3.4,0,0,0-3.475-3.922,8.856,8.856,0,0,0-1.994.152,26.1,26.1,0,0,0-7.085,2.795,3.009,3.009,0,0,0-1.672,2.137A37.839,37.839,0,0,0,75.226,68.3ZM37.359,73.673c.045-.191.083-.307.1-.426a42.276,42.276,0,0,0-.351-13.783.8.8,0,0,0-.374-.475c-1.309-.714-2.607-1.45-3.957-2.088a14.557,14.557,0,0,0-5.6-1.528c-2.106-.093-4.126.707-4.046,3.612a9.219,9.219,0,0,0,1.7,4.9C27.858,68.452,32.359,71.294,37.359,73.673Z"
            transform="translate(-21.365 -53.193)"
            fill="currentColor"
          />
          <path
            d="M196.571,197.4a22.269,22.269,0,0,1-10.219,3.793,20.607,20.607,0,0,1-13.822-3.567A37.712,37.712,0,0,0,196.571,197.4Z"
            transform="translate(-149.122 -176.508)"
            fill="currentColor"
          />
          <path
            d="M197.191,101.888H174.18c.888-.769,7.884-1.817,11.529-1.747a46.991,46.991,0,0,1,11.493,1.568C197.2,101.769,197.2,101.828,197.191,101.888Z"
            transform="translate(-150.551 -93.336)"
            fill="currentColor"
          />
          <path
            d="M175.05,160.359c7.6-.159,15.209-.3,22.812,0C193.628,162.314,179.418,162.3,175.05,160.359Z"
            transform="translate(-151.294 -144.683)"
            fill="currentColor"
          />
        </g>
        <g transform="translate(463 432)" fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="2">
          <circle cx="45" cy="45" r="45" stroke="none" />
          <circle cx="45" cy="45" r="46" fill="none" />
        </g>
      </g>
    </Icon>
  );
}

export default Mask;
