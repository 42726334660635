import {
  Box,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react';

import type IComponentParams from 'Core/types/IComponentParams';

import Button from '../Button';

interface ILibraryCard {
  title: string
  highlightTitle: string
  image: string
  content: string
  url: string
}

export const LibraryCardParams: IComponentParams[] = [
  {
    name: 'title',
    type: 'text',
    placeholder: 'Your',
    required: true,
  },
  {
    name: 'highlightTitle',
    type: 'text',
    placeholder: 'Title',
    required: true,
  },
  {
    name: 'image',
    type: 'image',
    placeholder: 'https://aflds.org/_next/static/media/science-explained.cb6d0423.jpg',
    required: true,
  },
  {
    name: 'content',
    type: 'textarea',
    placeholder: 'You can write anything',
    required: true,
  },
  {
    name: 'url',
    type: 'text',
    placeholder: '',
    required: true,
  },
];

export function LibraryCard({
  title, highlightTitle, image, content, url,
}: ILibraryCard): JSX.Element {
  return (
    <Box position="relative">
      <Flex
        height="100%"
        maxH={{
          '2xl': '320px',
        }}
        flexDirection={{
          base: 'column-reverse',
          lg: 'row-reverse',
        }}
        justifyContent="flex-end"
        bgColor="white"
        position="relative"
      >
        <Stack
          justifyContent={{ base: 'flex-start', lg: 'center' }}
          p="25px 40px"
          position="relative"
          w={{ base: '100%', lg: '64%' }}
          spacing="24px"
        >
          <Heading
            display="flex"
            alignItems="center"
            fontSize="26px"
            lineHeight="1"
            color="secondary.500"
          >
            <Text wordBreak="break-word">
              {title || ''}
              {' '}
              <Text as="span" color="primary.500">
                {highlightTitle}
              </Text>
            </Text>
          </Heading>
          <Stack spacing="12px">
            <Text wordBreak="break-word" fontWeight="500">{content}</Text>
          </Stack>
          <Flex>
            <Button
              colorScheme="secondary"
              variant="outline"
              withIcon
              link={url}
            >
              Read More
            </Button>
          </Flex>
        </Stack>
        <Box width={{ base: '100%', lg: '36%' }} bg="primary.500" as="figure">
          <Image
            w="100%"
            h="100%"
            src={image}
            alt={`${title ? `${title} ` : ''}${highlightTitle}`}
            objectFit="cover"
            objectPosition="center"
          />
        </Box>
      </Flex>
    </Box>
  );
}

export default LibraryCard;
