import IComponentParams from './IComponentParams';

interface ILineComponent {
  _id: string;
  column: number;
  component: string;
  data: unknown;
  params: IComponentParams[];
}

export const DEFAULT_VALUES = {
  _id: '',
  column: 0,
  component: '',
  data: {},
  params: [],
};

export default ILineComponent;
