import {
  Flex,
  Text,
  Grid,
} from '@chakra-ui/react';
import React from 'react';

interface IColumnBox {
  qtd: number;
  onClick: () => void;
}

const ColumnBox: React.FC<IColumnBox> = function ({ qtd, onClick }) {
  return (
    <Grid
      templateColumns={`repeat(${qtd}, 1fr)`}
      gap="16px"
      p="24px"
      borderRadius="10px"
      boxShadow="0px 3px 20px rgba(0,0,0,.04)"
      cursor="pointer"
      transition="0.3s"
      _hover={{
        boxShadow: '0px 3px 20px rgba(0,0,0,.1)',
      }}
      role="group"
      onClick={onClick}
    >
      {Array.from({ length: qtd }).map((el, index) => (
        <Flex
          p="24px"
          border="2px dashed"
          borderColor="gray.200"
          h="100px"
          alignItems="center"
          justifyContent="center"
          borderRadius="10px"
          color="gray.400"
          fontWeight="600"
          // eslint-disable-next-line react/no-array-index-key
          key={`column-${index}`}
          _groupHover={{
            color: 'gray.600',
            borderColor: 'gray.400',
          }}
          transition="0.3s"
          data-component-name="column"
        >
          <Text>
            {qtd}
            {' '}
            COLUMNS
          </Text>
        </Flex>
      ))}
    </Grid>
  );
};

export default ColumnBox;
