import { Icon, IconProps } from '@chakra-ui/react';

function LibertyCounsel(props: IconProps): JSX.Element {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 55" {...props}>
      <g transform="translate(-1207 -2274)">
        <g transform="translate(1209 2276)" fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="2">
          <circle cx="25.5" cy="25.5" r="25.5" stroke="none" />
          <circle cx="25.5" cy="25.5" r="26.5" fill="none" />
        </g>
        <g transform="translate(1223.901 2288.783)">
          <path
            d="M31.183,31.618H9.493A.493.493,0,0,1,9,31.125V5.383a.493.493,0,0,1,.493-.493H25.415a.493.493,0,0,1,.345.153l5.768,5.975a.493.493,0,0,1,.148.335V31.12a.493.493,0,0,1-.493.5Zm-21.2-.986h20.7V11.555L25.208,5.876H9.986Z"
            transform="translate(-9 -4.89)"
            fill="currentColor"
          />
          <path
            d="M41.793,11.846a.493.493,0,0,1-.493-.493V5.383a.493.493,0,0,1,.986,0v5.97A.493.493,0,0,1,41.793,11.846Z"
            transform="translate(-25.378 -4.89)"
            fill="currentColor"
          />
          <path
            d="M31.856,15.451h-2.81a.493.493,0,0,1,0-.986h2.81a.493.493,0,1,1,0,.986Zm-9.322,3.382H16.663a.493.493,0,0,1-.493-.493V12.493A.493.493,0,0,1,16.663,12h5.871a.493.493,0,0,1,.493.493v5.846A.493.493,0,0,1,22.534,18.832Zm-5.378-.986h4.885v-4.86H17.156Zm14.128,5H16.653a.493.493,0,1,1,0-.986H31.284a.493.493,0,0,1,0,.986Zm0,3.87H16.653a.493.493,0,0,1,0-.986H31.284a.493.493,0,0,1,0,.986Zm0,3.875H16.653a.493.493,0,1,1,0-.986H31.284a.493.493,0,0,1,0,.986Z"
            transform="translate(-12.63 -8.495)"
            fill="currentColor"
          />
        </g>
      </g>
    </Icon>

  );
}

export default LibertyCounsel;
