import { Icon, IconProps } from '@chakra-ui/react';

function Church(props: IconProps): JSX.Element {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 55" {...props}>
      <g transform="translate(-467 -2639)">
        <g transform="translate(469 2641)" fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="2">
          <ellipse cx="26" cy="25.5" rx="26" ry="25.5" stroke="none" />
          <ellipse cx="26" cy="25.5" rx="27" ry="26.5" fill="none" />
        </g>
        <g transform="translate(484.518 2653.167)">
          <line
            y1="12.641"
            transform="translate(4.216 9.624)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1.5"
          />
          <line
            y1="12.641"
            transform="translate(17.967 9.624)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1.5"
          />
          <path
            d="M5,15.016,14.454,9l9.454,6.016"
            transform="translate(-3.362 -3.924)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1.5"
          />
          <line
            x2="22"
            transform="translate(0.481 21.833)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1.5"
          />
          <line
            y2="5"
            transform="translate(11.481 -0.167)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1.5"
          />
          <line
            x2="4"
            transform="translate(9.481 1.833)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1.5"
          />
          <path
            d="M18.157,27.875H13V21.859A.86.86,0,0,1,13.859,21H17.3a.86.86,0,0,1,.859.859Z"
            transform="translate(-4.486 -5.611)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1.5"
          />
        </g>
      </g>
    </Icon>

  );
}

export default Church;
