import { useAuth0 } from '@auth0/auth0-react';
import {
  Box,
  Button,
  Container,
  Heading,
  Text,
  Flex,
  Spinner,
} from '@chakra-ui/react';
import { Navigate } from 'react-router-dom';

const Login: React.FC = function () {
  const { loginWithRedirect, isLoading, isAuthenticated } = useAuth0();

  return (
    !isLoading
      ? (
        <>
          {isAuthenticated && <Navigate to="/home" />}
          <Box>
            <Container>
              <Box
                p="32px"
                borderRadius="15px"
                boxShadow="0 20px 40px rgba(0,0,0,.05)"
                mt="124px"
                textAlign="center"
                bgColor="white"
              >
                <Heading mb="16px">Enterprise Level</Heading>
                <Text mb="32px">Login with the most secure authentication bridge system</Text>

                <Box textAlign="center">
                  <Button
                    colorScheme="blue"
                    onClick={() => loginWithRedirect()}
                  >
                    Authenticate with Auth0
                  </Button>
                </Box>
              </Box>
            </Container>
          </Box>
        </>
      )
      : (
        <Flex
          h="100vh"
          w="100%"
          alignItems="center"
          justifyContent="center"
        >
          <Spinner />
        </Flex>
      )
  );
};

export default Login;
