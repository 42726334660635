import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

export function BreadCrumbs(): JSX.Element {
  const { pathname } = useLocation();

  const routes = pathname.split('/').filter((p) => p.length > 0);
  const crumblist = routes.map((route, index) => {
    const location = `/${routes.slice(0, index + 1).join('/')}`;
    const text = route;

    return { location, text };
  });

  const paths = [{ location: '/', text: 'Home' }, ...crumblist];
  return (
    <Breadcrumb mb="15px">
      {paths ? paths.map((path, index) => (
        <BreadcrumbItem
          key={`bread-crumbs-${Math.floor(
            Math.random() * (9999 - 1000 + 1) + 1000,
          )}`}
          color={
            index === paths.length - 1 ? 'secondary.500' : 'primary.500'
          }
          fontWeight="600"
          fontSize="16px"
          isCurrentPage={index === paths.length - 1}
        >
          <BreadcrumbLink
            href={`${path.location}`}
            cursor={index === paths.length - 1 ? 'text' : 'pointer'}
            _hover={{ textDecoration: 'none' }}
          >
            {path.text}
          </BreadcrumbLink>
          )
        </BreadcrumbItem>
      )) : null}
    </Breadcrumb>
  );
}
