import { Icon, IconProps } from '@chakra-ui/react';

function PersonalStatementOne(props: IconProps): JSX.Element {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 55" {...props}>
      <g transform="translate(-1207 -2639)">
        <g transform="translate(1209 2641)" fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="2">
          <circle cx="25.5" cy="25.5" r="25.5" stroke="none" />
          <circle cx="25.5" cy="25.5" r="26.5" fill="none" />
        </g>
        <g transform="translate(1211.542 2644.09)">
          <g transform="translate(0 0.001)">
            <g transform="translate(22.69 0) rotate(45)">
              <path
                d="M1.572,0A1.55,1.55,0,0,0,0,1.513V24a.5.5,0,0,0,.01.112c0,.031-.008.06-.008.091a2.019,2.019,0,0,0,2.007,2.017H18.655a.5.5,0,0,0,.5-.5l0-.091a.5.5,0,0,0-.337-.477,1,1,0,0,1,0-1.893.5.5,0,0,0,.339-.475v-.051q0-.021,0-.041V1.513A1.474,1.474,0,0,0,18.692.439,1.609,1.609,0,0,0,17.587,0Zm0,1.008H17.587a.525.525,0,0,1,.563.5V22.184q-8.071,0-16.142,0l0,0a1.97,1.97,0,0,0-1,.28V1.513A.525.525,0,0,1,1.572,1.008Zm6.99,4.536a.5.5,0,0,0-.506.506V7.563H6.545a.5.5,0,0,0-.5.5v2.017a.5.5,0,0,0,.5.5l1.513,0v5.544a.5.5,0,0,0,.5.5h2.017a.5.5,0,0,0,.5-.5V10.59h1.513a.5.5,0,0,0,.506-.506V8.067a.5.5,0,0,0-.5-.5H11.084l0-1.515a.5.5,0,0,0-.5-.5Zm1.513,1.008V8.069a.5.5,0,0,0,.5.5l1.515,0,0,1.01H10.578a.5.5,0,0,0-.5.5V15.63H9.065V10.086a.5.5,0,0,0-.5-.5l-1.513,0V8.571H8.559a.5.5,0,0,0,.5-.5V6.554ZM2.007,23.192q7.728,0,15.46,0v0a1.724,1.724,0,0,0,0,2.015H2.009a.99.99,0,0,1-1-1.008h0A.99.99,0,0,1,2.007,23.192Z"
                transform="translate(0 13.548) rotate(-45)"
                fill="currentColor"
              />
            </g>
          </g>
        </g>
      </g>
    </Icon>
  );
}

export default PersonalStatementOne;
