import {
  Box,
  Text,
  Button,
} from '@chakra-ui/react';
import React, { ReactFragment } from 'react';
import { FaTrash, FaEdit } from 'react-icons/fa';

import useBuilder from 'Core/hooks/use-builder';
import useLine from 'Core/hooks/use-line';

import { ThemeAccordion, AccordionParams } from './Accordion';
import { BreadCrumbs } from './BreadCrumbs';
import { ContentCard, ContentCardParams } from './ContentCard';
import { CtaCard, CtaCardParams } from './CtaCard';
import { CtaCardAlt, CtaCardAltParams } from './CtaCardAlt';
import { DocumentCard, DocumentCardParams } from './DocumentCard';
import { FinalNotes, FinalNotesParams } from './FinalNotes';
import { Jotform, JotformParams } from './Jotform';
import { LibraryCard, LibraryCardParams } from './LibraryCard';
import { LogoCard, LogoCardParams } from './LogoCard';
import { MinimalistCard, MinimalistCardParams } from './MinimalistCard';
import { MinimalistContent, MinimalistContentParams } from './MinimalistContent';
import { MinimalistPostCard, MinimalistPostCardParams } from './MinimalistPostCard';
import { PageContentCard, PageContentCardParams } from './PageContentCard';
import { TwitterPosts, TwitterPostsParams } from './TwitterPosts';
import { VideoCard, VideoCardParams } from './VideoCard';

const components = [
  {
    name: 'BreadCrumbs',
    params: [],
  },
  {
    name: 'ContentCard',
    params: ContentCardParams,
  },
  {
    name: 'CtaCard',
    params: CtaCardParams,
  },
  {
    name: 'CtaCardAlt',
    params: CtaCardAltParams,
  },
  {
    name: 'DocumentCard',
    params: DocumentCardParams,
  },
  {
    name: 'FinalNotes',
    params: FinalNotesParams,
  },
  {
    name: 'Jotform',
    params: JotformParams,
  },
  {
    name: 'LibraryCard',
    params: LibraryCardParams,
  },
  {
    name: 'LogoCard',
    params: LogoCardParams,
  },
  {
    name: 'MinimalistCard',
    params: MinimalistCardParams,
  },
  {
    name: 'MinimalistContent',
    params: MinimalistContentParams,
  },
  {
    name: 'MinimalistPostCard',
    params: MinimalistPostCardParams,
  },
  {
    name: 'PageContentCard',
    params: PageContentCardParams,
  },
  {
    name: 'ThemeAccordion',
    params: AccordionParams,
  },
  {
    name: 'TwitterPosts',
    params: TwitterPostsParams,
  },
  {
    name: 'VideoCard',
    params: VideoCardParams,
  },
];

interface IAfldsComponents {
  component: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  componentId: string;
  preview?: boolean;
}

export const AfldsComponents: React.FC<IAfldsComponents> = function (
  {
    component, data, componentId, preview,
  },
) {
  const MyComponents: {
    [key: string]: ReactFragment
  } = {
    BreadCrumbs: <BreadCrumbs />,
    ContentCard: <ContentCard {...data} />,
    CtaCard: <CtaCard {...data} />,
    CtaCardAlt: <CtaCardAlt {...data} />,
    DocumentCard: <DocumentCard {...data} />,
    FinalNotes: <FinalNotes {...data} />,
    Jotform: <Jotform {...data} />,
    LibraryCard: <LibraryCard {...data} />,
    LogoCard: <LogoCard {...data} />,
    MinimalistCard: <MinimalistCard {...data} />,
    MinimalistContent: <MinimalistContent {...data} />,
    MinimalistPostCard: <MinimalistPostCard {...data} />,
    PageContentCard: <PageContentCard {...data} />,
    ThemeAccordion: <ThemeAccordion {...data} />,
    TwitterPosts: <TwitterPosts {...data} />,
    VideoCard: <VideoCard {...data} />,
    default: <Text>Invalid</Text>,
  };

  const {
    deleteOnOpen, setDeleteId, openUpdate, isVisualization,
  } = useBuilder();
  const { line } = useLine();

  return (
    <Box
      p="24px"
      transition="0.3s"
      role="group"
      position="relative"
      borderRadius="8px"
      border="2px dashed white"
      _hover={{
        borderColor: !preview ? 'gray.400' : 'white',
      }}
    >
      {!preview && (
        <Box
          opacity="0"
          transition="0.3s"
          position="absolute"
          top="8px"
          right="8px"
          _groupHover={{
            opacity: 1,
          }}
          zIndex="100"
        >
          <Button
            leftIcon={<FaTrash />}
            size="xs"
            colorScheme="red"
            mr="2"
            borderRadius="4px"
            onClick={() => {
              setDeleteId(componentId);
              deleteOnOpen();
            }}
            disabled={isVisualization}
          >
            Remove
          </Button>
          <Button
            leftIcon={<FaEdit />}
            size="xs"
            colorScheme="blue"
            mr="2"
            borderRadius="4px"
            onClick={() => openUpdate(line._id, componentId)}
            disabled={isVisualization}
          >
            Edit
          </Button>
        </Box>
      )}
      {MyComponents[component] || MyComponents.default}
    </Box>
  );
};

AfldsComponents.defaultProps = {
  preview: false,
};

export default components;
