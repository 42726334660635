import {
  Box,
  Heading,
  HStack,
  IconButton,
} from '@chakra-ui/react';
import React from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';

import useBuilder from 'Core/hooks/use-builder';

interface ILineBox {
  title: string;
  lineId?: string;
  lineColumns: number
}

const LineBox: React.FC<ILineBox> = function ({
  title, lineId, children, lineColumns,
}) {
  const { removeLine, openUpdateLine, isVisualization } = useBuilder();

  return (
    <Box
      borderRadius="10px"
      overflow="hidden"
      bgColor="white"
      boxShadow="0px 3px 20px rgba(0,0,0,.04)"
    >
      <HStack
        p="24px"
        color="white"
        bgColor="#1F1F1F"
        justifyContent="space-between"
      >
        <Heading fontSize="16px">{title}</Heading>
        {!!lineId && (
          <HStack>
            <IconButton
              aria-label="remove"
              icon={<FaEdit />}
              variant="ghost"
              size="xs"
              _hover={{
                color: 'gray.800',
                bg: 'white',
              }}
              onClick={() => openUpdateLine(lineId, lineColumns)}
              disabled={isVisualization}
            />
            <IconButton
              aria-label="remove"
              icon={<FaTrash />}
              variant="ghost"
              size="xs"
              _hover={{
                color: 'gray.800',
                bg: 'white',
              }}
              onClick={() => removeLine(lineId)}
              disabled={isVisualization}
            />
          </HStack>
        )}
      </HStack>

      <Box p="24px">{children}</Box>
    </Box>
  );
};

LineBox.defaultProps = {
  lineId: undefined,
};

export default LineBox;
