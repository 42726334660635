import React from 'react';

import IRoute from 'Core/types/IRoute';
import Builder from 'Pages/dashboard/Builder';
import Home from 'Pages/dashboard/Home';
import ShareProject from 'Pages/dashboard/ShareProject';
import Login from 'Pages/Login';

const routes: IRoute[] = [
  {
    path: '/',
    element: <Login />,
  },
  {
    path: '/home',
    element: <Home />,
    private: true,
  },
  {
    path: '/builder/:id',
    element: <Builder />,
    private: true,
  },
  {
    path: '/share/:id',
    element: <ShareProject />,
  },
];

export default routes;
