import { useContext } from 'react';

import { LineContext } from 'Core/context/LineProvider';
import ILineContext from 'Core/types/ILineContext';

const useLine = function (): ILineContext {
  const context = useContext(LineContext);

  return context;
};

export default useLine;
