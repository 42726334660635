import {
  Stack,
  Grid,
  Input,
  Select,
  Textarea,
  Box,
  Button,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

import useBuilder from 'Core/hooks/use-builder';
import api from 'Core/services/api';

const NewProjectForm: React.FC = function () {
  const { setPage } = useBuilder();

  const [name, setName] = useState<string>('');
  const [section, setSection] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  const [loading, setLoading] = useState<boolean>(false);

  const toast = useToast();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const navigate = useNavigate();

  const submit = async (): Promise<unknown> => {
    if (!name) {
      return toast({
        title: 'Invalid name.',
        description: 'Please type a valid name.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }

    if (!section) {
      return toast({
        title: 'Invalid section.',
        description: 'Please select a valid section.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }

    if (!description) {
      return toast({
        title: 'Invalid description.',
        description: 'Please type a valid description.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }

    try {
      setLoading(true);
      const { data } = await api.post('/pagebuilder/project', {
        name,
        section,
        description,
      });

      setPage(data);
      setLoading(false);

      return navigate(`/builder/${data._id}`);
    } catch (e) {
      return toast({
        title: 'API Error.',
        description: 'Something went wrong.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Button colorScheme="blue" onClick={onOpen}>Start New Project</Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW="lg">
          <ModalHeader>Start new project</ModalHeader>
          <ModalCloseButton />
          <ModalBody>

            <Stack spacing="24px" data-testid="newProjectForm">
              <Grid templateColumns={{ base: '1fr', lg: '1fr 1fr' }} gap="24px">
                <Input
                  variant="outline"
                  placeholder="Page name"
                  borderColor="blue.500"
                  onChange={(e) => setName(e.target.value)}
                />
                <Select
                  variant="outline"
                  placeholder="Section"
                  borderColor="blue.500"
                  onChange={(e) => setSection(e.target.value)}
                >
                  <option>About</option>
                  <option>Business</option>
                  <option>Citizen Corps</option>
                  <option>Community</option>
                  <option>Covid</option>
                  <option>Events</option>
                  <option>Health</option>
                  <option>Legal</option>
                  <option>Library</option>
                  <option>Medical</option>
                </Select>
              </Grid>
              <Textarea
                minH="200px"
                placeholder="Page description"
                borderColor="blue"
                p="16px"
                onChange={(e) => setDescription(e.target.value)}
              />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" variant="ghost" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Box>
              <Button
                rightIcon={<BsArrowRight size="25px" />}
                colorScheme="blue"
                onClick={submit}
                isLoading={loading}
              >
                Start Now
              </Button>
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default NewProjectForm;
