import {
  Box,
  Flex,
  Text,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Stack,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { IoAddCircleOutline } from 'react-icons/io5';

import useBuilder from 'Core/hooks/use-builder';

import ColumnBox from './ColumnBox';

const AddLineBox: React.FC = function () {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { addLine, isVisualization } = useBuilder();

  const handleColumnBoxClick = (columns: number): void => {
    addLine(columns);
    onClose();
  };

  return (
    <Box pt="24px">
      {
        !isVisualization
        && (
          <Box
            p="24px"
            borderRadius="10px"
            bgColor="white"
            boxShadow="0px 3px 20px rgba(0,0,0,.04)"
            cursor="pointer"
            onClick={onOpen}
          >
            <Flex
              p="24px"
              border="2px dashed"
              borderColor="gray.200"
              h="100px"
              alignItems="center"
              justifyContent="center"
              borderRadius="10px"
              color="gray.400"
              fontWeight="600"
              transition="0.3s"
              _hover={{
                color: 'gray.600',
                borderColor: 'gray.400',
              }}
            >
              <HStack spacing="8px">
                <Text>ADD SECTION</Text>
                <IoAddCircleOutline size="30px" />
              </HStack>
            </Flex>
          </Box>
        )
      }

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW={{ base: '100%', lg: '80%', xl: '1200px' }}>
          <ModalHeader
            bgColor="#1F1F1F"
            fontWeight="600"
            color="white"
            borderTopRadius="7px"
          >
            Select columns
          </ModalHeader>
          <ModalCloseButton color="white" />
          <ModalBody p="32px">
            <Stack spacing="24px">
              <ColumnBox qtd={1} onClick={() => handleColumnBoxClick(1)} />
              <ColumnBox qtd={2} onClick={() => handleColumnBoxClick(2)} />
              <ColumnBox qtd={3} onClick={() => handleColumnBoxClick(3)} />
              <ColumnBox qtd={4} onClick={() => handleColumnBoxClick(4)} />
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default AddLineBox;
