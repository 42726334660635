import ILineComponent from 'Core/types/ILineComponent';

interface ILine {
  _id: string;
  columns: number;
  components: ILineComponent[];
}

export const DEFAULT_VALUES = {
  _id: '',
  columns: 0,
  components: [],
};

export default ILine;
