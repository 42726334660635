import {
  Box,
  Stack,
  Image,
  Heading,
  Button,
  Text,
  Link,
} from '@chakra-ui/react';
import React from 'react';
import { BsArrowRight } from 'react-icons/bs';

import ReactIcon from 'Components/elements/Icon';
import IComponentParams from 'Core/types/IComponentParams';

interface ICtaCard {
  headingIcon?: string;
  overtitle?: string;
  title: string;
  subtitle: string;
  ctaText: string;
  ctaLink: string;
  ctaImage?: string;
  ctaIsExternal?: boolean;
  headingAlignment?: string;
}

export const CtaCardParams: IComponentParams[] = [
  {
    name: 'title',
    type: 'text',
    placeholder: 'Your title',
    required: true,
  },
  {
    name: 'subtitle',
    type: 'text',
    placeholder: 'Subtitle',
    required: true,
  },
  { name: 'overtitle', type: 'text', placeholder: 'Overtitle' },
  {
    name: 'ctaText',
    type: 'text',
    placeholder: 'Cta text',
    required: true,
  },
  {
    name: 'ctaLink',
    type: 'text',
    placeholder: 'Cta link',
    required: true,
  },
  { name: 'ctaImage', type: 'image' },
  // {
  //   label: 'Cta is external',
  //   name: 'ctaIsExternal',
  //   type: 'boolean'
  // },
  // {
  //   name: 'headingAlignment',
  //   type: 'select',
  //   options: [
  //     { label: 'Right', value: 'flex-end' },
  //     { label: 'Center', value: 'center' },
  //     { label: 'Left', value: 'flex-start' }
  //   ],
  //   required: true
  // },
  { name: 'headingIcon', type: 'icon' },
];

export const CtaCard: React.FC<ICtaCard> = function ({
  headingIcon,
  overtitle,
  title,
  subtitle,
  ctaText,
  ctaLink,
  ctaImage,
  ctaIsExternal,
  children,
  headingAlignment,
  ...rest
}) {
  return (
    <Box
      bg="white"
      p="30px"
      borderRadius="5px"
      boxShadow="0px 10px 20px rgba(0,0,0,.04)"
      {...rest}
    >
      <Box>
        <Stack direction="row" spacing="2" alignItems={headingAlignment}>
          {!!headingIcon
            && (headingIcon.includes('http') ? (
              <Image src={headingIcon} height="60px" alt="headingIcon" />
            ) : (
              <ReactIcon iconName={headingIcon} type="fa" />
            ))}
          <Box>
            <Text wordBreak="break-word" textTransform="uppercase" fontWeight="500">
              {overtitle}
            </Text>
            <Heading
              wordBreak="break-word"
              fontWeight="800"
              fontSize={{ base: '20px', lg: '25px' }}
              color="secondary.500"
              lineHeight="1.2em"
              mb="5px"
            >
              {title}
            </Heading>
            <Text
              textTransform="uppercase"
              wordBreak="break-word"
              fontSize="14px"
              color="primary.500"
              fontWeight="600"
            >
              {subtitle}
            </Text>
          </Box>
        </Stack>
        <Box color="secondary.500" fontSize="0.9em" mt="32px">
          <Box>{children}</Box>
          {ctaLink && (
            <Box mt="16px">
              <Link href={ctaLink} target={ctaIsExternal ? '_blank' : '_self'}>
                <Button
                  colorScheme="primary"
                  textTransform="uppercase"
                  fontWeight="400"
                  px="20px"
                  rightIcon={<BsArrowRight size="25px" />}
                  fontSize="0.95em"
                >
                  {ctaText}
                </Button>
              </Link>
            </Box>
          )}
          {ctaImage ? <Image src={ctaImage} alt="cta image" /> : null}
        </Box>
      </Box>
    </Box>
  );
};

CtaCard.defaultProps = {
  headingIcon: undefined,
  overtitle: undefined,
  ctaIsExternal: false,
  headingAlignment: 'flex-start',
  ctaImage: undefined,
};
