/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Text,
  Button,
  useToast,
  FormControl,
  FormLabel,
  Select,
} from '@chakra-ui/react';
import React, {
  createContext,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';

import api from 'Core/services/api';
import IBuilderContext, { DEFAULT_VALUES } from 'Core/types/IBuilderContext';
import IComponentParams from 'Core/types/IComponentParams';
import ILine from 'Core/types/ILine';
import ILineComponent from 'Core/types/ILineComponent';
import IPage, { DEFAULT_VALUES as PAGE_DEFAULT_VALUES } from 'Core/types/IPage';

export const BuilderContext = createContext<IBuilderContext>(DEFAULT_VALUES);

const BuilderProvider: React.FC = function ({ children }) {
  const [page, setPage] = useState<IPage>(PAGE_DEFAULT_VALUES);
  const [lines, setLines] = useState<ILine[]>([]);

  const [isVisualization, setIsVisualization] = useState(true);

  useEffect(() => {
    setIsVisualization(page.published);
  }, [page.published]);

  const {
    isOpen: deleteProjectIsOpen,
    onOpen: deleteProjectOnOpen,
    onClose: deleteProjectOnClose,
  } = useDisclosure();
  const {
    isOpen: deleteIsOpen,
    onOpen: deleteOnOpen,
    onClose: deleteOnClose,
  } = useDisclosure();
  const {
    isOpen: updateIsOpen,
    onOpen: updateOnOpen,
    onClose: updateOnClose,
  } = useDisclosure();
  const {
    isOpen: updateLineIsOpen,
    onOpen: updateLineOnOpen,
    onClose: updateLineOnClose,
  } = useDisclosure();

  const [componentToUpdate, setComponentToUpdate] = useState<ILineComponent>();
  const [updating, setUpdating] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string>('');
  const [deleting, setDeleting] = useState<boolean>(false);

  const toast = useToast();

  const apiError = useCallback((): void => {
    toast({
      title: 'API Error.',
      description: 'Something went wrong.',
      status: 'error',
      duration: 9000,
      isClosable: true,
    });
  }, [toast]);

  const addComponent = useCallback(
    async (
      lineId: string,
      column: number,
      component: string,
      data: unknown,
      params: IComponentParams[],
    ): Promise<void> => {
      const linesAux: ILine[] = lines;

      try {
        const { data: res } = await api.post('/pagebuilder/component', {
          _section: lineId,
          column,
          component,
          params,
          data,
        });

        for (let i = 0; i < linesAux.length; i += 1) {
          if (linesAux[i]._id === lineId) {
            linesAux[i].components.push(res);
          }
        }
        setLines([...linesAux]);
      } catch (e) {
        apiError();
      }
    },
    [lines, apiError],
  );

  const deleteProject = useCallback(async (): Promise<void> => {
    try {
      setDeleting(true);
      await api.delete(`/pagebuilder/project/${deleteId}`);

      deleteOnClose();
      setDeleteId('');
      setDeleting(false);
      window.location.href = '/home';
    } catch (e) {
      apiError();
    }
  }, [apiError, deleteId, deleteOnClose]);

  const deleteComponent = useCallback(async (): Promise<void> => {
    const linesAux: ILine[] = lines;

    try {
      setDeleting(true);
      await api.delete(`/pagebuilder/component/${deleteId}`);

      for (let i = 0; i < linesAux.length; i += 1) {
        linesAux[i].components = linesAux[i].components.filter(
          (component) => component._id !== deleteId,
        );
      }

      setLines([...linesAux]);
      deleteOnClose();
      setDeleteId('');
      setDeleting(false);
    } catch (e) {
      apiError();
    }
  }, [lines, deleteId, deleteOnClose, apiError]);

  const openUpdate = useCallback(
    (lineId: string, componentId: string): void => {
      const line = lines.find((l) => l._id === lineId);
      const component = line?.components.find((c) => c._id === componentId);
      setComponentToUpdate(component);
      updateOnOpen();
    },
    [lines, updateOnOpen],
  );

  const updateComponent = useCallback(
    async (
      lineId: string,
      componentId: string,
      values: unknown,
    ): Promise<void> => {
      const linesAux: ILine[] = lines;

      try {
        setUpdating(true);
        const { data: updatedData } = await api.patch(
          `/pagebuilder/component/${componentId}`,
          { data: values },
        );

        for (let i = 0; i < linesAux.length; i += 1) {
          if (linesAux[i]._id === lineId) {
            for (let j = 0; j < linesAux[i].components.length; j += 1) {
              if (linesAux[i].components[j]._id === componentId) {
                linesAux[i].components[j] = updatedData;
              }
            }
          }
        }

        setLines([...linesAux]);
        setUpdating(false);
      } catch (e) {
        apiError();
      }
    },
    [lines, apiError],
  );

  const addLine = useCallback(
    async (columns: number): Promise<void> => {
      try {
        const { data } = await api.post('/pagebuilder/section', {
          _project: page._id,
          columns,
        });

        setLines((linesEl) => [...linesEl, data]);
      } catch (e) {
        apiError();
      }
    },
    [page._id, setLines, apiError],
  );

  const removeLine = useCallback(
    async (id: string): Promise<void> => {
      try {
        api.delete(`/pagebuilder/section/${id}`);
        setLines((linesEl) => linesEl.filter((line) => line._id !== id));
      } catch (e) {
        apiError();
      }
    },
    [setLines, apiError],
  );

  const [updateLineId, setUpdateLineId] = useState('');
  const [lineNewColumns, setLineNewColumns] = useState(0);

  const openUpdateLine = useCallback((id: string, columns: number): void => {
    setUpdateLineId(id);
    setLineNewColumns(columns);
    updateLineOnOpen();
  }, [updateLineOnOpen]);

  const updateLine = useCallback(
    async (): Promise<void> => {
      try {
        api.patch(`/pagebuilder/section/${updateLineId}`, { columns: lineNewColumns });
        const linesAux = [...lines];
        for (let i = 0; i < linesAux.length; i += 1) {
          if (linesAux[i]._id === updateLineId) {
            linesAux[i].columns = lineNewColumns;
          }
        }
        setLines(linesAux);
        updateLineOnClose();
      } catch (e) {
        apiError();
      }
    },
    [setLines, apiError, lines, lineNewColumns, updateLineId, updateLineOnClose],
  );

  const sendToReview = useCallback(async (): Promise<void> => {
    try {
      const { data } = await api.patch(`/pagebuilder/project/${page._id}`);

      setPage(data);
      return;
    } catch (e) {
      apiError();
    }
  }, [apiError, setPage, page._id]);

  const memoizedValues = useMemo(
    () => ({
      page,
      setPage,
      lines,
      setLines,
      addComponent,
      addLine,
      removeLine,
      deleteOnOpen,
      setDeleteId,
      updateComponent,
      updateOnOpen,
      updateIsOpen,
      updateOnClose,
      openUpdate,
      componentToUpdate,
      updating,
      deleting,
      openUpdateLine,
      deleteProjectOnOpen,
      sendToReview,
      isVisualization,
      setIsVisualization,
    }),
    [
      page,
      lines,
      setLines,
      addComponent,
      addLine,
      removeLine,
      deleteOnOpen,
      setDeleteId,
      updateComponent,
      updateOnOpen,
      updateIsOpen,
      updateOnClose,
      openUpdate,
      componentToUpdate,
      updating,
      deleting,
      openUpdateLine,
      deleteProjectOnOpen,
      sendToReview,
      isVisualization,
      setIsVisualization,
    ],
  );

  return (
    <BuilderContext.Provider value={memoizedValues}>
      {children}
      <Modal isOpen={deleteIsOpen} onClose={deleteOnClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete component</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Are you sure you want to remove this component? You can not undo
              this action.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={deleteOnClose}>
              Cancel
            </Button>
            <Button
              colorScheme="red"
              onClick={deleteComponent}
              isLoading={deleting}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={deleteProjectIsOpen} onClose={deleteProjectOnClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete component</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Are you sure you want to remove this component? You can not undo
              this action.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={deleteProjectOnClose}>
              Cancel
            </Button>
            <Button
              colorScheme="red"
              onClick={deleteProject}
              isLoading={deleting}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={updateLineIsOpen} onClose={updateLineOnClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update section</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Section columns</FormLabel>
              <Select
                defaultValue={lineNewColumns}
                onChange={(e) => setLineNewColumns(e.target.value as unknown as number)}
                bgColor="white"
                color="black"
                size="sm"
                borderRadius="8px"
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
              </Select>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={updateLineOnClose}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={() => updateLine()}
              isLoading={deleting}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </BuilderContext.Provider>
  );
};

export default BuilderProvider;
