import IComponent, { DEFAULT_VALUES as C_DEFAULT_VALUES } from './IComponent';

interface IComponenteConfigContext {
  configIsOpen: boolean;
  configOnClose: () => void;
  configOnOpen: () => void;
  component: IComponent;
  openComponentConfig: (newComponent: IComponent) => void;
  activeColumn: number;
  setActiveColumn: (column: number) => void;
}

export const DEFAULT_VALUES = {
  configIsOpen: false,
  configOnClose: (): void => undefined,
  configOnOpen: (): void => undefined,
  component: C_DEFAULT_VALUES,
  openComponentConfig: (): void => undefined,
  activeColumn: 0,
  setActiveColumn: (): void => undefined,
};

export default IComponenteConfigContext;
