import {
  Button as ChakraButton,
  Flex,
  Box,
  Link,
  ButtonProps,
} from '@chakra-ui/react';
import React, { ReactFragment, useState } from 'react';
import { BsArrowRight } from 'react-icons/bs';

interface IButton {
  variant: string;
  colorScheme: string;
  iconColor?: string;
  color?: string;
  icon?: ReactFragment;
  withIcon?: boolean;
  link?: string;
  target?: string;
}

const Button: React.FC<IButton & ButtonProps> = function ({
  variant,
  colorScheme,
  iconColor,
  color,
  children,
  icon,
  withIcon,
  link,
  target,
  ...rest
}) {
  const [hovered, setHovered] = useState(false);

  return (
    <Box pl={withIcon ? '20px' : 0}>
      {link ? (
        <Link href={link} target={target} _hover={{ textDecoration: 'none' }}>
          <ChakraButton
            colorScheme={colorScheme}
            borderWidth="2px"
            borderColor={`${colorScheme}.500`}
            color={color}
            variant={variant}
            fontWeight="500"
            borderRadius="0px"
            pl={withIcon ? '30px' : '25px'}
            pr="25px"
            py="15px"
            position="relative"
            textTransform="uppercase"
            _focus={{
              outline: 'none',
            }}
            transition="0.3s"
            _hover={{ bgColor: `${colorScheme}.500`, color: 'white' }}
            maxW="100%"
            whiteSpace="break-spaces"
            fontSize="12px"
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            {...rest}
          >
            {withIcon && (
              <Flex
                w="35px"
                h="35px"
                borderRadius="100px"
                color="white"
                alignItems="center"
                justifyContent="center"
                position="absolute"
                left="-18px"
                transition="0.3s"
                bgColor={iconColor || `${colorScheme}.500`}
                boxShadow={hovered ? '0px 0px 10px rgba(0, 0, 0, 0.5)' : 'none'}
              >
                {icon || <BsArrowRight size="25px" />}
              </Flex>
            )}
            {children}
          </ChakraButton>
        </Link>
      ) : (
        <ChakraButton
          colorScheme={colorScheme}
          borderWidth="2px"
          borderColor={`${colorScheme}.500`}
          color={color}
          variant={variant}
          fontWeight="500"
          borderRadius="0px"
          pl={withIcon ? '30px' : '25px'}
          pr="25px"
          py="15px"
          position="relative"
          textTransform="uppercase"
          _focus={{
            outline: 'none',
          }}
          transition="0.3s"
          _hover={{ bgColor: `${colorScheme}.500`, color: 'white' }}
          maxW="100%"
          whiteSpace="break-spaces"
          fontSize="12px"
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          {...rest}
        >
          {withIcon && (
            <Flex
              w="35px"
              h="35px"
              borderRadius="100px"
              color="white"
              alignItems="center"
              justifyContent="center"
              position="absolute"
              left="-18px"
              transition="0.3s"
              bgColor={iconColor || `${colorScheme}.500`}
              boxShadow={hovered ? '0px 0px 10px rgba(0, 0, 0, 0.5)' : 'none'}
            >
              {icon || <BsArrowRight size="25px" />}
            </Flex>
          )}
          {children}
        </ChakraButton>
      )}
    </Box>
  );
};

Button.defaultProps = {
  iconColor: undefined,
  color: undefined,
  icon: undefined,
  withIcon: false,
  link: undefined,
  target: undefined,
};

export default Button;
