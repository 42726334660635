import {
  Accordion,
  Stack,
  Box,
} from '@chakra-ui/react';

import Item from './AccordionItem';

interface IThemeAccordion {
  icon?: string
  title: string
  content: string
}

export const AccordionParams = [
  {
    name: 'title',
    type: 'text',
    placeholder: 'Title',
    required: true,
  },
  {
    name: 'content',
    type: 'textarea',
    placeholder: 'Content',
    required: true,
  },
];

export const ThemeAccordion: React.FC<IThemeAccordion> = function ({
  title, content, ...rest
}) {
  return (
    <Box {...rest}>
      {/* <HStack mb="32px">
        {icon ? <Image src={icon} alt="accordion item icon" /> : null}
        <Heading fontSize="16px" color="primary.500">
          {title}
        </Heading>
      </HStack> */}
      <Accordion allowMultiple>
        <Stack spacing="32px">
          <Item title={title}>{content}</Item>
        </Stack>
      </Accordion>
    </Box>
  );
};
