import React, { useEffect, useState } from 'react';
import * as Icons from 'react-icons/fa';

import * as AFLDSIcons from 'Components/aflds/Icons';

interface IReactIcon {
  iconName: string
  type: 'fa' | 'aflds'
}

const ReactIcon: React.FC<IReactIcon> = function ({ iconName, type = 'fa' }) {
  const [icon, setIcon] = useState<React.ReactElement>();

  useEffect(() => {
    if (type === 'fa') {
      Object.entries(Icons).forEach(([name, Icon]) => {
        if (name === iconName) setIcon(<Icon size="30px" />);
      });
    }

    if (type === 'aflds') {
      Object.entries(AFLDSIcons).forEach(([name, Icon]) => {
        if (name === iconName) setIcon(<Icon width="64px" height="64px" />);
      });
    }
  }, [iconName, type]);

  return icon || <div />;
};

export default ReactIcon;
