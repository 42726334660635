import { Icon, IconProps } from '@chakra-ui/react';

function RabbiGreen(props: IconProps): JSX.Element {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 56" {...props}>
      <g transform="translate(-1207 -1948)">
        <g transform="translate(1209 1950)" fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="2">
          <ellipse cx="25.5" cy="26" rx="25.5" ry="26" stroke="none" />
          <ellipse cx="25.5" cy="26" rx="26.5" ry="27" fill="none" />
        </g>
        <g transform="translate(1218.602 1961.435)">
          <rect
            width="31"
            height="32"
            transform="translate(0.398 -0.435)"
            fill="none"
          />
          <path
            d="M45.752,42.745V36.4a2.442,2.442,0,0,1,4.884,0V46.653a9.768,9.768,0,0,1-9.768,9.768c-5.395,0-7.815-2.931-11.792-11.321a2.442,2.442,0,0,1,4.23-2.442L35.984,47.3V34.442a2.442,2.442,0,0,1,4.884,0v8.3"
            transform="translate(-25.742 -28.597)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            d="M132.884,28.7V18.442a2.442,2.442,0,0,0-4.884,0V28.7"
            transform="translate(-112.875 -14.55)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
      </g>
    </Icon>
  );
}

export default RabbiGreen;
