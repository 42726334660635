/* eslint-disable no-nested-ternary */
import {
  Box,
  Stack,
  Image,
  Heading,
  Text,
  Link,
  Flex,
  HStack,
} from '@chakra-ui/react';
import React from 'react';
import { IoCalendarClearOutline } from 'react-icons/io5';

import ReactIcon from 'Components/elements/Icon';
import IComponentParams from 'Core/types/IComponentParams';

import Button from '../Button';

export const ContentCardParams: IComponentParams[] = [
  { name: 'overtitle', type: 'text', required: true },
  { name: 'titleIcon', type: 'icon' },
  { name: 'title', type: 'text', required: true },
  { name: 'highlightedTitle', placeholder: 'Your', type: 'text' },
  { name: 'subtitle', type: 'text', required: true },
  { name: 'image', type: 'image' },
  { name: 'withCta', type: 'boolean' },
  { name: 'ctaText', type: 'text' },
  { name: 'ctaLink', type: 'text' },
  { name: 'date', placeholder: '04/04/2022', type: 'text' },
  { name: 'cardLink', type: 'text' },
  { name: 'dateAfterTitle', placeholder: '05/09/2022', type: 'text' },
  {
    name: 'children',
    label: 'Text content',
    placeholder: 'Text content',
    type: 'textarea',
  },
];

interface IContentCard {
  overtitle?: string;
  titleIcon?: string;
  title?: string;
  highlightedTitle?: string;
  subtitle?: string;
  subtitleColor?: string;
  children?: string;
  titleColor?: string;
  titleSize?: string;
  titleAlignment?: string;
  justifyContent?: string;
  alignContent?: string;
  image?: string;
  imageRatio?: string;
  imageWidth?: string;
  imagePosition?: string;
  withCta?: boolean;
  ctaText?: string;
  ctaLink?: string;
  ctaExternal?: string;
  date?: string;
  cardLink?: string;
  dateAfterTitle?: string;
}

export const ContentCard: React.FC<IContentCard> = function ({
  overtitle,
  titleIcon,
  title,
  highlightedTitle,
  subtitle,
  subtitleColor,
  children,
  titleColor,
  titleSize,
  titleAlignment,
  justifyContent,
  alignContent,
  image,
  imageRatio,
  imageWidth,
  imagePosition,
  withCta,
  ctaText,
  ctaLink,
  ctaExternal,
  date,
  cardLink,
  dateAfterTitle,
  ...rest
}) {
  return (
    <Flex
      bg="white"
      boxShadow="0px 10px 20px rgba(0,0,0,.04)"
      flexDir="column"
      justifyContent={justifyContent}
      alignContent={alignContent}
      display={
        imagePosition === 'top' || imagePosition === 'bottom' ? 'block' : 'flex'
      }
      transition="0.3s"
      cursor={cardLink ? 'pointer' : 'default'}
      _hover={{
        opacity: cardLink ? 0.7 : 1,
      }}
      {...rest}
    >
      <Stack
        direction={{
          base: 'column',
          lg: 'row',
        }}
        alignContent={alignContent}
        display={
          imagePosition === 'top' || imagePosition === 'bottom'
            ? 'block'
            : 'flex'
        }
        h="100%"
      >
        {image ? (
          <Box
            width={{
              base: '100%',
              lg:
                imagePosition === 'top' || imagePosition === 'bottom'
                  ? '100%'
                  : imageWidth || '40%',
            }}
            pt={{ base: '50%', lg: imageRatio || '50%' }}
            bgColor="primary.500"
            bgImage={image}
            bgSize="cover"
            bgPos="center"
            bgRepeat="no-repeat"
          />
        ) : null}
        <Flex
          direction="column"
          justifyContent={justifyContent}
          alignItems={alignContent}
          p={{ base: '20px', lg: '30px' }}
          width={{
            base: '100%',
            lg: image
              ? imagePosition === 'top' || imagePosition === 'bottom'
                ? '100%'
                : '60%'
              : '100%',
          }}
        >
          {(title || highlightedTitle || subtitle) && (
            <Stack direction="row" alignItems={titleAlignment || 'center'}>
              {!!titleIcon
                && (titleIcon.includes('http') ? (
                  <Image src={titleIcon} height="60px" alt="headingIcon" />
                ) : (
                  <ReactIcon iconName={titleIcon} type="fa" />
                ))}
              <Box ml="-0.5em">
                {date && !dateAfterTitle ? (
                  <HStack spacing="8px" mb="16px">
                    <IoCalendarClearOutline />
                    {' '}
                    <Text fontSize="12px" fontWeight="500">
                      {date}
                    </Text>
                  </HStack>
                ) : null}
                {overtitle ? (
                  <Text
                    fontSize="14px"
                    mb="8px"
                    textTransform="uppercase"
                    fontWeight="500"
                  >
                    {overtitle}
                  </Text>
                ) : null}
                <Heading
                  fontWeight="700"
                  fontSize={
                    typeof titleSize === 'object'
                      ? titleSize
                      : { base: '20px', lg: titleSize || '25px' }
                  }
                  color={titleColor || 'secondary.500'}
                  lineHeight="1.2em"
                >
                  {highlightedTitle ? (
                    <Box display="inline" color="primary.500">
                      {`${highlightedTitle} `}
                    </Box>
                  ) : null}
                  {title}
                </Heading>
                {date && dateAfterTitle ? (
                  <HStack spacing="8px" mt="16px">
                    <IoCalendarClearOutline />
                    {' '}
                    <Text fontSize="12px" fontWeight="500">
                      {date}
                    </Text>
                  </HStack>
                ) : null}
                {subtitle ? (
                  <Text
                    textTransform="uppercase"
                    fontSize="14px"
                    color={subtitleColor || 'primary.500'}
                    fontWeight="600"
                  >
                    {subtitle}
                  </Text>
                ) : null}
              </Box>
            </Stack>
          )}
          {children ? (
            <Box
              color="secondary.500"
              fontSize="0.9em"
              mt={title || highlightedTitle || subtitle ? '18px' : 0}
              ml="-0.5em"
            >
              <Box>{children}</Box>
            </Box>
          ) : null}
          {withCta ? (
            <Link
              target={ctaExternal ? '_blank' : '_self'}
              href={ctaLink}
              _hover={{ textDecor: 'none' }}
            >
              <Button withIcon colorScheme="secondary" variant="outline">
                {ctaText || 'Read More'}
              </Button>
            </Link>
          ) : null}
        </Flex>
      </Stack>
    </Flex>
  );
};

ContentCard.defaultProps = {
  overtitle: undefined,
  titleIcon: undefined,
  title: undefined,
  highlightedTitle: undefined,
  subtitle: undefined,
  subtitleColor: undefined,
  children: undefined,
  titleColor: undefined,
  titleSize: undefined,
  titleAlignment: undefined,
  justifyContent: undefined,
  alignContent: undefined,
  image: undefined,
  imageRatio: undefined,
  imageWidth: undefined,
  imagePosition: undefined,
  withCta: undefined,
  ctaText: undefined,
  ctaLink: undefined,
  ctaExternal: undefined,
  date: undefined,
  cardLink: undefined,
  dateAfterTitle: undefined,
};
