/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-nested-ternary */
import { useAuth0 } from '@auth0/auth0-react';
import {
  ChakraProvider,
  Spinner,
  Flex,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
  Outlet,
  Navigate,
} from 'react-router-dom';

import routes from 'Core/config/routes';
import theme from 'Core/config/theme';
import BuilderProvider from 'Core/context/BuilderProvider';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import api from 'Core/services/api';

const PrivateRoute: React.FC = function () {
  const { isAuthenticated, isLoading } = useAuth0();
  return (
    isLoading
      ? (
        <Flex
          h="100vh"
          w="100%"
          alignItems="center"
          justifyContent="center"
        >
          <Spinner />
        </Flex>
      )
      : isAuthenticated
        ? (
          <Outlet />
        )
        : <Navigate to="/" />
  );
};

const App: React.FC = function () {
  const {
    isAuthenticated, getIdTokenClaims,
  } = useAuth0();

  useEffect(() => {
    async function getToken(): Promise<void> {
      api.interceptors.request.use(
        async (config) => {
          const data = await getIdTokenClaims();
          // @ts-ignore
          config.headers.common.Authorization = `Bearer ${data.__raw || null}`;
          return config;
        },
        (error) => Promise.reject(error),
      );
    }
    getToken();
  }, [isAuthenticated, getIdTokenClaims]);

  return (
    <ChakraProvider theme={theme}>
      <BuilderProvider>
        <BrowserRouter>
          <Routes>
            {routes.map((route) => (
              route.private
                ? (
                  <Route key={route.path} path={route.path} element={<PrivateRoute />}>
                    <Route path={route.path} element={route.element} />
                  </Route>
                )
                : (
                  <Route
                    path={route.path}
                    element={route.element}
                    key={route.path}
                  />
                )
            ))}
          </Routes>
        </BrowserRouter>
      </BuilderProvider>
    </ChakraProvider>
  );
};

export default App;
