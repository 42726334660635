import {
  Link,
  Heading,
  Text,
  Flex,
  Box,
  Button,
} from '@chakra-ui/react';
import { FiArrowRight } from 'react-icons/fi';

import Date from 'Core/util/date';

interface INews {
  newsSlug: string
  title: string
  date: string | Date
}

export default function Desktop({ newsSlug, title, date }: INews): JSX.Element {
  return (
    <Flex
      key={newsSlug}
      href={`/news/post/${newsSlug}`}
      flexDir="column"
      p="24px"
      bg="white"
      _hover={{ textDecor: 'none' }}
      css={{
        gap: '8px',
      }}
      as={Link}
    >
      <Flex w="100%" alignItems="center" css={{ gap: '8px' }}>
        <Box w="5%" h="2px" bg="primary.500" />
        <Text fontSize="14px" fontWeight="semibold" color="primary.500">
          {Date.format(date, '{mmm} {dd}, {yyyy}')}
        </Text>
      </Flex>
      <Heading fontSize="20px" fontWeight="normal" as="h2">
        {title}
      </Heading>
      <Button
        h="48px"
        d="flex"
        justifyContent="flex-start"
        borderRadius="0px"
        px="0"
        mt="10px"
        overflow="hidden"
        bg="transparent"
        _hover={{ bg: 'transparent' }}
        _active={{ bg: 'transparent' }}
      >
        <Flex
          w="48px"
          h="100%"
          alignItems="center"
          justifyContent="center"
          mr="12px"
          bg="primary.500"
          color="white"
        >
          <FiArrowRight />
        </Flex>
        <Text textTransform="uppercase" fontWeight="normal">
          See more
        </Text>
      </Button>
    </Flex>
  );
}
