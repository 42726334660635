/* eslint-disable react/no-array-index-key */
import { Grid, Stack } from '@chakra-ui/react';
import React from 'react';

import { AfldsComponents } from 'Components/aflds';
import ComponentsModal from 'Components/elements/ComponentsModal';
import LineBox from 'Components/elements/LineBox';
import useBuilder from 'Core/hooks/use-builder';
import useLine from 'Core/hooks/use-line';
import { v4 as uuidv4 } from 'uuid';

const Line: React.FC = function () {
  const { isVisualization } = useBuilder();
  const { line } = useLine();

  return (
    <LineBox title="Section" lineId={line._id} lineColumns={line.columns}>
      <Grid
        templateColumns={{ base: '1fr', lg: `repeat(${line.columns}, 1fr)` }}
        gap="24px"
      >
        {Array.from({ length: line.columns }).map((el, index) => (
          <Stack spacing="16px" key={`column-${index}`}>
            {line.components
              .filter((component) => component.column === index)
              .map((component) => (
                <AfldsComponents
                  component={component.component}
                  data={component.data}
                  componentId={component._id}
                  key={component._id}
                />
              ))}
            {
              !isVisualization
              && <ComponentsModal key={uuidv4()} column={index} />
            }
          </Stack>
        ))}
      </Grid>
    </LineBox>
  );
};

export default Line;
