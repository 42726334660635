import { Icon, IconProps } from '@chakra-ui/react';

function PersonalStatementTwo(props: IconProps): JSX.Element {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" {...props}>
      <g transform="translate(-467 -1948)">
        <g transform="translate(469 1950)" fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="2">
          <circle cx="26" cy="26" r="26" stroke="none" />
          <circle cx="26" cy="26" r="27" fill="none" />
        </g>
        <g transform="translate(483.987 1963.284)">
          <path
            d="M32.438,23.764l3.466-6a.4.4,0,0,0,0-.389.379.379,0,0,0-.334-.195H28.637l-3.466-6a.409.409,0,0,0-.675,0l-3.46,6H14.1a.379.379,0,0,0-.334.195.4.4,0,0,0,0,.389l3.466,6-3.466,6a.4.4,0,0,0,0,.389.379.379,0,0,0,.334.195h6.933l3.46,6a.418.418,0,0,0,.341.189.391.391,0,0,0,.334-.195l3.46-6h6.933a.379.379,0,0,0,.334-.195.4.4,0,0,0,0-.389Zm.122,4.458a.379.379,0,0,1-.334.195h-4.7l-2.347,4.075a.385.385,0,0,1-.669,0L22.16,28.416h-4.7a.379.379,0,0,1-.334-.195.4.4,0,0,1,0-.389l2.347-4.069-2.347-4.075a.4.4,0,0,1,0-.389.379.379,0,0,1,.334-.195h4.7l2.347-4.075a.409.409,0,0,1,.675,0l2.347,4.075h4.7a.379.379,0,0,1,.334.195.4.4,0,0,1,0,.389l-2.347,4.075,2.347,4.069A.387.387,0,0,1,32.56,28.222Z"
            transform="translate(-13.717 -10.998)"
            fill="currentColor"
          />
          <path
            d="M37.16,36.362h3.582l-1.794-3.1Z"
            transform="translate(-22.903 -19.721)"
            fill="currentColor"
          />
          <path
            d="M20.95,36.362h3.582l-1.788-3.1Z"
            transform="translate(-16.551 -19.721)"
            fill="currentColor"
          />
          <path
            d="M30.844,19.23,29.05,22.338h3.588Z"
            transform="translate(-19.725 -14.224)"
            fill="currentColor"
          />
          <path
            d="M40.742,25.61H37.16l1.788,3.1Z"
            transform="translate(-22.903 -16.724)"
            fill="currentColor"
          />
          <path
            d="M30.844,42.742l1.794-3.1H29.05Z"
            transform="translate(-19.725 -22.221)"
            fill="currentColor"
          />
          <path
            d="M24.532,25.61H20.95l1.794,3.1Z"
            transform="translate(-16.551 -16.724)"
            fill="currentColor"
          />
          <path
            d="M31.36,25.61H26.878L24.64,29.49l2.238,3.874H31.36L33.6,29.49Zm-2.244,5.364a1.487,1.487,0,1,1,1.49-1.484A1.491,1.491,0,0,1,29.116,30.974Z"
            transform="translate(-17.997 -16.724)"
            fill="currentColor"
          />
        </g>
      </g>
    </Icon>

  );
}

export default PersonalStatementTwo;
