import { Icon, IconProps } from '@chakra-ui/react';

function Location(props: IconProps): JSX.Element {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 58" {...props}>
      <g transform="translate(-261.5 -2262.601)">
        <g transform="translate(263.5 2264.601)" fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="2">
          <circle cx="27" cy="27" r="27" stroke="none" />
          <circle cx="27" cy="27" r="28" fill="none" />
        </g>
        <g transform="translate(271.188 2275.219)">
          <path
            d="M19.312,33.766l-.582-.842C18.293,32.29,8,17.321,8,11.312a11.312,11.312,0,0,1,22.624,0c0,6.009-10.293,20.978-10.73,21.612Zm0-32.352a9.909,9.909,0,0,0-9.9,9.9c0,4.8,7.7,16.653,9.9,19.947,2.2-3.294,9.9-15.149,9.9-19.947A9.909,9.909,0,0,0,19.312,1.414Z"
            fill="currentColor"
          />
          <path
            d="M21.949,18.9A4.949,4.949,0,1,1,26.9,13.949,4.955,4.955,0,0,1,21.949,18.9Zm0-8.484a3.535,3.535,0,1,0,3.535,3.535A3.539,3.539,0,0,0,21.949,10.414Z"
            transform="translate(-2.637 -2.637)"
            fill="currentColor"
          />
        </g>
      </g>
    </Icon>
  );
}

export default Location;
