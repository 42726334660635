import {
  Link,
  Heading,
  Text,
  Flex,
} from '@chakra-ui/react';

import Date from 'Core/util/date';

interface INews {
  newsSlug: string
  title: string
  date: string | Date
}

export default function Mobile({ newsSlug, title, date }: INews): JSX.Element {
  return (
    <Flex
      key={newsSlug}
      href={`/news/post/${newsSlug}`}
      flexDir="column"
      _hover={{ textDecor: 'none' }}
      css={{ gap: '12px' }}
      as={Link}
    >
      <Heading color="black" fontSize="16px" lineHeight="16px" as="h4">
        {title}
      </Heading>
      <Text fontSize="12px" color="primary.500">
        {Date.relative(date)}
      </Text>
    </Flex>
  );
}
