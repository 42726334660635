import { Icon, IconProps } from '@chakra-ui/react';

function Docs(props: IconProps): JSX.Element {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 58" {...props}>
      <g transform="translate(-420.061 -1141.061)">
        <g transform="translate(422.061 1143.061)" fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="2">
          <circle cx="27" cy="27" r="27" stroke="none" />
          <circle cx="27" cy="27" r="28" fill="none" />
        </g>
        <path
          d="M22.082,7.254a3.093,3.093,0,0,1,.638,1.01,3.067,3.067,0,0,1,.266,1.17V24.745a1.271,1.271,0,0,1-1.276,1.276H3.847a1.271,1.271,0,0,1-1.276-1.276V3.479A1.271,1.271,0,0,1,3.847,2.2H15.755a3.068,3.068,0,0,1,1.17.266,3.093,3.093,0,0,1,1.01.638Zm-5.9-3.243v5h5a1.452,1.452,0,0,0-.292-.545L16.725,4.3a1.452,1.452,0,0,0-.545-.292Zm5.1,20.308V10.71H15.755a1.271,1.271,0,0,1-1.276-1.276V3.9H4.272V24.319H21.284Zm-6.831-7.881a10.113,10.113,0,0,0,1.116.744,13.189,13.189,0,0,1,1.555-.093q1.954,0,2.352.651a.642.642,0,0,1,.027.691.038.038,0,0,1-.013.027l-.027.027V18.5q-.08.505-.944.505a5.483,5.483,0,0,1-1.528-.266,9.691,9.691,0,0,1-1.728-.7,23.932,23.932,0,0,0-5.21,1.1Q8.02,22.618,6.837,22.618a.773.773,0,0,1-.372-.093l-.319-.159q-.013-.013-.08-.066a.549.549,0,0,1-.08-.478A2.886,2.886,0,0,1,6.731,20.6a6.423,6.423,0,0,1,1.754-1.283.194.194,0,0,1,.306.08.077.077,0,0,1,.027.053q.691-1.13,1.422-2.618a20.285,20.285,0,0,0,1.382-3.482,10.744,10.744,0,0,1-.405-2.12A5.168,5.168,0,0,1,11.3,9.54q.146-.532.558-.532h.292a.56.56,0,0,1,.465.2,1.062,1.062,0,0,1,.12.9.288.288,0,0,1-.053.106.346.346,0,0,1,.013.106v.4a17.4,17.4,0,0,1-.186,2.552,6.724,6.724,0,0,0,1.94,3.163ZM6.8,21.9a5.868,5.868,0,0,0,1.821-2.1,7.576,7.576,0,0,0-1.163,1.116A4.418,4.418,0,0,0,6.8,21.9Zm5.29-12.228a3.952,3.952,0,0,0-.027,1.754q.013-.093.093-.585,0-.04.093-.571a.3.3,0,0,1,.053-.106.038.038,0,0,1-.013-.027.027.027,0,0,0-.007-.02.027.027,0,0,1-.007-.02A.765.765,0,0,0,12.1,9.62a.038.038,0,0,1-.013.027v.027Zm-1.648,8.785a19.5,19.5,0,0,1,3.775-1.077,2,2,0,0,1-.173-.126,2.38,2.38,0,0,1-.213-.179,7.04,7.04,0,0,1-1.688-2.339,17.761,17.761,0,0,1-1.1,2.618q-.4.744-.6,1.1Zm8.586-.213a3.181,3.181,0,0,0-1.861-.319,5.027,5.027,0,0,0,1.648.372,1.289,1.289,0,0,0,.239-.013q0-.013-.027-.04Z"
          transform="translate(436.67 1156.386)"
          fill="currentColor"
        />
      </g>
    </Icon>
  );
}

export default Docs;
