/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useDisclosure } from '@chakra-ui/react';
import React, {
  createContext,
  useState,
  useMemo,
  useCallback,
} from 'react';

import IComponent, { DEFAULT_VALUES as C_DEFAULT_VALUES } from 'Core/types/IComponent';
import IComponenteConfigContext, { DEFAULT_VALUES } from 'Core/types/IComponentConfigContext';

export const ComponentConfigContext = createContext<IComponenteConfigContext>(DEFAULT_VALUES);

const ComponentConfigProvider: React.FC = function ({ children }) {
  const {
    isOpen: configIsOpen,
    onClose: configOnClose,
    onOpen: configOnOpen,
  } = useDisclosure();

  const [component, setComponent] = useState<IComponent>(C_DEFAULT_VALUES);

  const [activeColumn, setActiveColumn] = useState<number>(0);

  const openComponentConfig = useCallback(
    (newComponent: IComponent): void => {
      setComponent(newComponent);
      configOnOpen();
    },
    [configOnOpen],
  );

  const memoizedValues = useMemo(
    () => ({
      configIsOpen,
      configOnClose,
      configOnOpen,
      component,
      openComponentConfig,
      activeColumn,
      setActiveColumn,
    }),
    [
      configIsOpen,
      configOnClose,
      configOnOpen,
      component,
      openComponentConfig,
      activeColumn,
      setActiveColumn,
    ],
  );

  return (
    <ComponentConfigContext.Provider value={memoizedValues}>
      {children}
    </ComponentConfigContext.Provider>
  );
};

export default ComponentConfigProvider;
