import { Icon, IconProps } from '@chakra-ui/react';

function OmniJournal(props: IconProps): JSX.Element {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 58" {...props}>
      <g transform="translate(-474 -1898)">
        <g transform="translate(476 1900)" fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="2">
          <circle cx="27" cy="27" r="27" stroke="none" />
          <circle cx="27" cy="27" r="28" fill="none" />
        </g>
        <g transform="translate(1438.621 -1693.053)">
          <path
            d="M-921.962,3630.858a3.418,3.418,0,0,1-.63,1.362,2.577,2.577,0,0,1-1.971.968c-2.1.024-4.2.012-6.3.01-.293,0-.477-.161-.484-.392s.182-.405.491-.405q2.988,0,5.975,0a1.926,1.926,0,0,0,2.114-2.121q0-10.365,0-20.73c0-.664-.173-.836-.838-.836q-9.75,0-19.5.006a1.249,1.249,0,0,0-.578.139c-.228.125-.224.381-.224.618q0,4.963,0,9.925a1.7,1.7,0,0,1-.021.318.361.361,0,0,1-.418.322.36.36,0,0,1-.355-.363c-.008-.1-.006-.2-.006-.293q0-3.428,0-6.857v-.344h-.606c-.809,0-1.618,0-2.427,0-.53,0-.744.215-.744.745q0,8.738,0,17.475a1.882,1.882,0,0,0,1.731,1.978,1.879,1.879,0,0,0,2-1.562,5.273,5.273,0,0,0,.039-.638q0-4.389,0-8.778c0-.462.119-.649.406-.643s.394.2.395.636c0,2.943-.005,5.887,0,8.831a2.954,2.954,0,0,1-.683,2.047.871.871,0,0,0-.069.125h11.943a2.177,2.177,0,0,1,.32.009.365.365,0,0,1,.338.354.356.356,0,0,1-.275.4,1.04,1.04,0,0,1-.289.033q-6.949,0-13.9,0a2.7,2.7,0,0,1-2.768-2.766q0-8.764,0-17.529a1.4,1.4,0,0,1,1.532-1.521q1.36,0,2.721,0h.325c0-.346,0-.672,0-1,0-.382-.008-.765.007-1.147a1.357,1.357,0,0,1,1.288-1.319c.115-.008.231-.006.347-.006,6.447,0,12.893.008,19.34-.01a1.589,1.589,0,0,1,1.758,1.13Z"
            transform="translate(0)"
            fill="currentColor"
          />
          <path
            d="M-828.543,3743.961c-.871,0-1.742,0-2.612,0a.945.945,0,0,1-1.062-1.046q-.008-2.64,0-5.279a.948.948,0,0,1,1.061-1.05q2.626,0,5.251,0a.963.963,0,0,1,1.067,1.075q0,2.613,0,5.226a.959.959,0,0,1-1.066,1.073C-826.783,3743.963-827.663,3743.961-828.543,3743.961Zm.012-.8c.853,0,1.706-.008,2.559,0,.256,0,.34-.079.339-.337-.009-1.7,0-3.395,0-5.092,0-.334-.016-.351-.344-.351h-5.091c-.327,0-.345.017-.345.347,0,1.7,0,3.395,0,5.092,0,.25.071.345.333.341C-830.237,3743.151-829.384,3743.16-828.531,3743.16Z"
            transform="translate(-109.238 -120.084)"
            fill="currentColor"
          />
          <path
            d="M-823.7,3884.354h7.736c.385,0,.58.135.58.4s-.2.4-.579.4h-15.5a1.08,1.08,0,0,1-.239-.012.374.374,0,0,1-.331-.415.371.371,0,0,1,.356-.366,1.86,1.86,0,0,1,.266-.007Z"
            transform="translate(-109.417 -257.98)"
            fill="currentColor"
          />
          <path
            d="M-823.715,3933.667h7.6c.089,0,.178,0,.267,0,.273.014.446.169.446.394a.421.421,0,0,1-.419.4,2.55,2.55,0,0,1-.266,0H-831.37a2.04,2.04,0,0,1-.319-.014.377.377,0,0,1-.358-.417.371.371,0,0,1,.384-.365c.1-.007.2,0,.293,0Z"
            transform="translate(-109.399 -303.999)"
            fill="currentColor"
          />
          <path
            d="M-719.389,3641.545q-.172.72-.345,1.441c-.082.338-.216.449-.551.461a.534.534,0,0,1-.637-.429q-.6-1.9-1.2-3.81c-.09-.288-.012-.432.277-.517a.376.376,0,0,1,.54.285c.274,1.02.585,2.03.882,3.044.024.084.052.167.1.314.185-.75.36-1.437.521-2.127a.394.394,0,0,1,.425-.351.408.408,0,0,1,.469.362c.152.673.326,1.34.541,2.018.029-.085.061-.17.087-.256q.436-1.5.87-2.992c.09-.309.229-.386.528-.3s.38.224.282.535q-.6,1.905-1.2,3.81c-.105.333-.262.431-.645.413-.329-.016-.453-.121-.53-.449q-.172-.723-.345-1.445Z"
            transform="translate(-211.947 -28.697)"
            fill="currentColor"
          />
          <path
            d="M-829.739,3641.332q0-1.158,0-2.316c0-.321.116-.437.428-.435s.421.114.422.439c0,1.28,0,2.559,0,3.838,0,.193.008.379-.227.453a.57.57,0,0,1-.662-.206c-.126-.2-.232-.419-.346-.63-.339-.63-.676-1.26-1.053-1.962-.012.153-.023.226-.023.3,0,.675,0,1.351,0,2.026,0,.38-.1.5-.42.5s-.427-.11-.427-.484q0-1.893,0-3.785c0-.174-.041-.372.191-.444a.588.588,0,0,1,.665.127,2.111,2.111,0,0,1,.27.425c.378.721.75,1.445,1.124,2.168Z"
            transform="translate(-109.4 -28.626)"
            fill="currentColor"
          />
          <path
            d="M-772.186,3639.262v1.312c.292,0,.574,0,.855,0s.38.1.379.332-.114.322-.387.324-.548,0-.842,0v1.316h1.763c.232,0,.311.146.318.344s-.052.387-.3.389q-1.147.01-2.294,0c-.244,0-.36-.126-.359-.388.008-1.325,0-2.65,0-3.975,0-.283.091-.389.382-.393.747-.01,1.494-.009,2.24,0,.229,0,.34.15.324.395-.014.213-.1.35-.346.347-.48-.006-.96,0-1.44,0Z"
            transform="translate(-164.457 -28.569)"
            fill="currentColor"
          />
          <path
            d="M-633.045,3637.676a8.369,8.369,0,0,1,.881.206.381.381,0,0,1,.265.527c-.084.251-.211.293-.466.23a4.951,4.951,0,0,0-.956-.156.463.463,0,0,0-.493.368.467.467,0,0,0,.3.54c.352.188.72.344,1.068.539a1.4,1.4,0,0,1,.668,1.572,1.31,1.31,0,0,1-1.229,1.036,2.061,2.061,0,0,1-1.539-.367.473.473,0,0,1-.161-.674c.139-.218.3-.239.511-.09a2.63,2.63,0,0,0,.609.361,1.083,1.083,0,0,0,.573,0,.529.529,0,0,0,.257-.914,1.443,1.443,0,0,0-.366-.258c-.319-.174-.65-.326-.965-.506a1.158,1.158,0,0,1-.571-1.249,1.134,1.134,0,0,1,.835-1A7.2,7.2,0,0,1-633.045,3637.676Z"
            transform="translate(-293.474 -27.784)"
            fill="currentColor"
          />
          <path
            d="M-701.724,3736.58h3.545a1.261,1.261,0,0,1,.265.014.339.339,0,0,1,.3.376.767.767,0,0,1-.276.374c-.06.052-.187.034-.284.034q-3.532,0-7.064,0c-.053,0-.107,0-.16,0a.4.4,0,0,1-.417-.4.393.393,0,0,1,.435-.4c.675-.006,1.351,0,2.026,0Z"
            transform="translate(-227.203 -120.079)"
            fill="currentColor"
          />
          <path
            d="M-701.681,3835.8h-3.465a2.419,2.419,0,0,1-.266-.007.362.362,0,0,1-.357-.36.358.358,0,0,1,.3-.407,1.278,1.278,0,0,1,.29-.026h7.01a1.826,1.826,0,0,1,.187,0,.423.423,0,0,1,.42.4.4.4,0,0,1-.443.395c-1.226,0-2.452,0-3.679,0Z"
            transform="translate(-227.24 -211.923)"
            fill="currentColor"
          />
          <path
            d="M-701.668,3786.683q-1.732,0-3.465,0a1.759,1.759,0,0,1-.291-.018.363.363,0,0,1-.338-.405.366.366,0,0,1,.376-.37c.079-.007.16-.006.24-.006h6.956c.08,0,.16,0,.24.005a.4.4,0,0,1,.4.384.41.41,0,0,1-.386.4,2.011,2.011,0,0,1-.266.01Z"
            transform="translate(-227.248 -166.088)"
            fill="currentColor"
          />
        </g>
      </g>
    </Icon>
  );
}

export default OmniJournal;
