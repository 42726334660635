import {
  Box,
  Stack,
  Text,
  Link,
  Flex,
  HStack,
} from '@chakra-ui/react';
import { IoCalendarClearOutline } from 'react-icons/io5';

import ReactIcon from 'Components/elements/Icon';
import IComponentParams from 'Core/types/IComponentParams';
import Date from 'Core/util/date';

import Button from '../Button';

export const PageContentCardParams: IComponentParams[] = [
  {
    name: 'overtitle', type: 'text', placeholder: 'Overtitle',
  },
  {
    name: 'title', type: 'text', placeholder: 'Title',
  },
  {
    name: 'highlightedTitle', type: 'text', placeholder: 'Your',
  },
  {
    name: 'highlightedTitleAfter', type: 'boolean',
  },
  {
    name: 'titleIcon', type: 'aflds_icon',
  },
  {
    name: 'content', type: 'textarea', placeholder: 'You can write anything',
  },
  {
    name: 'image',
    type: 'image',
  },
  {
    name: 'date', type: 'date', required: true,
  },
  { name: 'withCta', type: 'boolean' },
  {
    name: 'ctaLink', type: 'text', placeholder: '/', required: true,
  },
  {
    name: 'ctaText', type: 'text', placeholder: 'Link', required: true,
  },
  { name: 'ctaExternal', type: 'boolean' },
];

interface IPageContentCard {
  overtitle: string
  title: string
  titleIcon: string
  highlightedTitle: string
  highlightedTitleAfter: boolean
  date: string | Date
  image: string
  content: string
  withCta: boolean
  ctaExternal: boolean
  ctaLink: string
  ctaText: string
}

export function PageContentCard({
  overtitle,
  title,
  titleIcon,
  highlightedTitle,
  highlightedTitleAfter,
  date,
  image,
  content,
  withCta,
  ctaText,
  ctaLink,
  ctaExternal,
}: IPageContentCard): JSX.Element {
  return (
    <Flex
      bg="white"
      boxShadow="0px 10px 20px rgba(0,0,0,.04)"
      flexDir="column"
      justifyContent="center"
      transition="0.3s"
      _hover={{ opacity: ctaLink ? 0.7 : 1 }}
    >
      <Flex
        h="100%"
        flexDir={{
          base: 'column',
          lg: 'row',
        }}
      >
        {image ? (
          <Box
            width={{
              base: '100%',
              lg: '40%',
            }}
            pt="20%"
            bgColor="primary.500"
            bgImage={image}
            bgSize="cover"
            bgPos="center"
            bgRepeat="no-repeat"
          />
        ) : null}
        <Flex
          direction="column"
          justifyContent="center"
          p={{
            base: '20px',
            lg: '30px',
          }}
          width={{
            base: '100%',
            lg: image
              ? '60%'
              : '100%',
          }}
        >
          <Stack
            w="100%"
            direction="row"
            alignItems="center"
          >
            {titleIcon ? (
              <ReactIcon iconName={titleIcon} type="aflds" />
            ) : null}
            <Box ml="-0.5em" w="100%">
              {date ? (
                <HStack spacing="8px" mb="16px">
                  <IoCalendarClearOutline />
                  {' '}
                  <Text fontSize="12px" fontWeight="500">
                    {Date.format(date, '{mmm} {dd}, {yyyy}')}
                  </Text>
                </HStack>
              ) : null}
              {overtitle ? (
                <Text
                  fontSize="14px"
                  mb="8px"
                  textTransform="uppercase"
                  fontWeight="500"
                >
                  {overtitle}
                </Text>
              ) : null}
              <Box
                fontWeight="700"
                fontSize={{ base: '18px', '2xl': '20px' }}
                color="secondary.500"
                lineHeight="1.2em"
              >
                {highlightedTitle && !highlightedTitleAfter ? (
                  <Text display="inline" color="primary.500">
                    {highlightedTitle}
                    {' '}
                  </Text>
                ) : null}
                <Text display="inline" dangerouslySetInnerHTML={{ __html: title }} />
                {highlightedTitle && highlightedTitleAfter ? (
                  <Text display="inline" color="primary.500">
                    {' '}
                    {highlightedTitle}
                  </Text>
                ) : null}
              </Box>
            </Box>
          </Stack>
          {content ? (
            <Box
              color="secondary.500"
              fontSize="0.9em"
              mt="18px"
              ml="-0.5em"
            >
              <Box pos="relative">{content}</Box>
            </Box>
          ) : null}
          {withCta ? (
            <Link
              target={ctaExternal ? '_blank' : '_self'}
              href={ctaLink}
              _hover={{ textDecoration: 'none' }}
            >
              <Button
                withIcon
                mt="20px"
                colorScheme="secondary"
                variant="outline"
              >
                {ctaText || 'Read More'}
              </Button>
            </Link>
          ) : null}
        </Flex>
      </Flex>
    </Flex>
  );
}
