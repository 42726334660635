interface IPage {
  _id: string
  name: string
  section: string
  description: string
  published?: boolean
  createdAt: string,
  author?: {
    name: string,
    picture: string
  }
}

export const DEFAULT_VALUES = {
  _id: '',
  name: '',
  section: '',
  description: '',
  published: false,
  createdAt: '',
  author: undefined,
};

export default IPage;
