import { useContext } from 'react';

import { BuilderContext } from 'Core/context/BuilderProvider';
import IBuilderContext from 'Core/types/IBuilderContext';

const useBuilder = function (): IBuilderContext {
  const context = useContext(BuilderContext);

  return context;
};

export default useBuilder;
