import IComponentParams from 'Core/types/IComponentParams';

interface IComponent {
  name: string;
  params: IComponentParams[];
}

export const DEFAULT_VALUES = {
  name: '',
  params: [],
};

export default IComponent;
