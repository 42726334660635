import {
  Heading,
  Container,
  Box,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

import ProjectsCarousel from 'Components/elements/ProjectsCarousel';
import Topbar from 'Components/layout/Topbar';
import api from 'Core/services/api';

const UserHome: React.FC = function () {
  const [sections, setSections] = useState([]);

  const toast = useToast();

  useEffect(() => {
    async function getData(): Promise<void> {
      try {
        const { data } = await api.get('/pagebuilder/project');
        setSections(data);
      } catch (e) {
        toast({
          title: 'API Error.',
          description: 'Something went wrong.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    }
    getData();
  }, [toast]);

  return (
    <Box>
      <Topbar />
      <Container maxW="container.xl">
        <Box mt="32px">
          <Box mt="32px">
            <Heading as="h2" fontSize="24px">
              My Projects
            </Heading>

            {
              sections.map((section) => (
                <Box mt="16px" key={section.name}>
                  <ProjectsCarousel title={section.name} items={section.items} />
                </Box>
              ))
            }
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default UserHome;
