import { Text } from '@chakra-ui/react';

import IComponentParams from 'Core/types/IComponentParams';

interface ITwitterPosts {
  twitterAccount: string
}

export const TwitterPostsParams: IComponentParams[] = [
  {
    name: 'twitterAccount',
    type: 'text',
    placeholder: 'Twitter username',
    required: true,
  },
];

export const TwitterPosts: React.FC<ITwitterPosts> = function ({ twitterAccount }) {
  return twitterAccount ? (
    <>
      <a
        className="twitter-timeline"
        href={`https://twitter.com/${twitterAccount}`}
      >
        Tweets by
        {' '}
        {twitterAccount}
      </a>
      <script
        async
        src="https://platform.twitter.com/widgets.js"
        charSet="utf-8"
      />
    </>
  ) : <Text fontWeight="500">Waiting for account username</Text>;
};
