import {
  Box,
  Flex,
  Heading,
  Image,
  Text,
} from '@chakra-ui/react';

import AFLDSLogoIcon from 'assets/icons/aflds-logo-icon.svg';
import IComponentParams from 'Core/types/IComponentParams';

import Button from '../Button';

export const LogoCardParams: IComponentParams[] = [
  {
    name: 'title', type: 'text', placeholder: 'Title', required: true,
  },
  {
    name: 'highlightedTitle', type: 'text', placeholder: 'Your', required: true,
  },
  { name: 'ctaExternal', type: 'boolean' },
  {
    name: 'ctaLink', type: 'text', placeholder: '/', required: true,
  },
  {
    name: 'ctaText', type: 'text', placeholder: 'Link', required: true,
  },
];

interface ILogoCard {
  title: string
  highlightedTitle: string
  ctaExternal: boolean
  ctaLink: string
  ctaText: string
}

export function LogoCard({
  title, highlightedTitle, ctaExternal, ctaLink, ctaText,
}: ILogoCard): JSX.Element {
  return (
    <Flex alignItems="center">
      <Image
        src={AFLDSLogoIcon}
        position="relative"
        zIndex="10"
        width="130px"
        transform="translateX(-12px)"
        alt={`${highlightedTitle} ${title}`}
      />
      <Box
        bg="white"
        py="25px"
        ml="-66px"
        borderRadius="5px"
        boxShadow="0px 10px 20px rgba(0,0,0,.04)"
        pl="65px"
        pr="32px"
        width={{ base: '100%', lg: 'calc(100% - 130pxpx)' }}
        textAlign={{ base: 'center', lg: 'left' }}
      >
        <Heading as="h2" color="primary.500" fontSize="18px" mb="24px">
          {`${highlightedTitle} `}
          {' '}
          <Text display="inline" color="secondary.500">
            {title}
          </Text>
        </Heading>
        <Button
          link={ctaLink}
          target={ctaExternal ? '_blank' : '_self'}
          colorScheme="secondary"
          variant="outlined"
          withIcon
        >
          {ctaText || 'See more'}
        </Button>
      </Box>
    </Flex>
  );
}
