import { Icon, IconProps } from '@chakra-ui/react';

function BookHome(props: IconProps): JSX.Element {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 59 59" {...props}>
      <g transform="translate(-463 -486)">
        <g transform="translate(465 488)" fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="2">
          <circle cx="27.5" cy="27.5" r="27.5" stroke="none" />
          <circle cx="27.5" cy="27.5" r="28.5" fill="none" />
        </g>
        <g transform="translate(-283.909 166.781)">
          <path
            d="M803.835,474.1v-.5q0-7.462.011-14.925c0-.335-.088-.485-.421-.576a34.191,34.191,0,0,0-6.451-1.043c-1.606-.121-3.218-.161-4.828-.236-.136-.006-.273,0-.46,0,.032.353.057.664.09.975.029.27-.022.512-.335.531s-.384-.223-.406-.488c-.034-.4-.081-.808-.123-1.212-.04-.388.091-.561.479-.546,1.952.075,3.912.082,5.853.272,1.9.185,3.783.561,5.674.852a.2.2,0,0,0,.158-.036c-2.742-2.644-6.184-3.254-9.856-3.409.044.381.074.7.119,1.023.036.255,0,.478-.294.519s-.4-.144-.432-.41c-.055-.466-.133-.929-.184-1.4a.377.377,0,0,1,.417-.465c3.278.042,6.463.485,9.27,2.346a25.525,25.525,0,0,1,2.174,1.808c-.082.018-.048.021-.036.006a11.184,11.184,0,0,1,6.249-3.6,21.616,21.616,0,0,1,4.981-.564c.389,0,.535.167.486.543-.059.453-.125.9-.182,1.357-.032.257-.166.408-.426.374s-.331-.239-.3-.482c.041-.326.081-.651.13-1.039a18.62,18.62,0,0,0-5.413.79,10.5,10.5,0,0,0-4.509,2.619,1,1,0,0,0,.218.012,46.233,46.233,0,0,1,9.13-1.076c.786-.027,1.573-.04,2.36-.049.44,0,.56.146.511.586-.042.378-.084.757-.114,1.136-.022.272-.071.548-.408.524-.367-.026-.36-.322-.328-.605s.054-.553.082-.846a.814.814,0,0,0-.193-.051,49.876,49.876,0,0,0-10.993,1.137c-.966.232-.965.239-.965,1.213q0,7.233,0,14.466v.451c.707-.287,1.358-.561,2.017-.816a29.206,29.206,0,0,1,7.044-1.854,9.186,9.186,0,0,1,1.25-.1c.343.006.44-.135.446-.441a2.513,2.513,0,0,1,.089-.752.564.564,0,0,1,.371-.331c.232-.028.361.166.342.4a13.392,13.392,0,0,1-.161,1.473.558.558,0,0,1-.4.346,28.515,28.515,0,0,0-10.99,2.93.857.857,0,0,1-.787-.017,31.763,31.763,0,0,0-7.97-2.6c-.947-.162-1.914-.217-2.873-.287-.362-.027-.529-.156-.546-.522s-.064-.758-.114-1.136c-.036-.273-.041-.544.294-.6s.41.2.438.483c.032.326-.014.745.169.945.159.174.59.106.9.132a29.556,29.556,0,0,1,8.645,2.286C803.085,473.778,803.431,473.927,803.835,474.1Z"
            transform="translate(-27.796 -110.746)"
            fill="currentColor"
          />
          <path
            d="M782.127,335.955c0-.764,0-1.469,0-2.174,0-.549.08-.628.643-.629q2,0,4,0c.584,0,.663.079.664.651,0,1.472.008,2.944-.006,4.416a.538.538,0,0,0,.347.581c1.29.623,2.563,1.282,3.85,1.914a.568.568,0,0,1,.377.587c-.014.393,0,.787,0,1.18-.006.519-.237.658-.707.423q-3.982-1.99-7.962-3.983c-2.166-1.084-4.336-2.162-6.5-3.257a.827.827,0,0,0-.839,0q-7.19,3.613-14.39,7.206c-.068.034-.135.072-.205.1-.332.13-.56,0-.572-.361-.016-.469,0-.939,0-1.409,0-.285.188-.386.4-.492q5.514-2.753,11.027-5.51c1.27-.635,2.544-1.265,3.809-1.91a.7.7,0,0,1,.711,0c1.629.826,3.264,1.638,4.9,2.453C781.8,335.8,781.932,335.864,782.127,335.955Zm9.1,6.073c.094-.424.05-.685-.37-.882-1.263-.592-2.5-1.243-3.752-1.852a.649.649,0,0,1-.416-.679c.015-1.421.007-2.842.005-4.263,0-.146-.012-.293-.019-.441h-3.75c-.022.047-.041.069-.041.091q-.008,1.142-.012,2.283c0,.65-.212.78-.785.493-1.791-.9-3.587-1.787-5.372-2.695a.615.615,0,0,0-.63.013q-7.056,3.537-14.119,7.062c-.476.238-.483.252-.352.86.164-.079.321-.152.477-.229q6.976-3.486,13.949-6.978a.71.71,0,0,1,.711-.016q6.987,3.506,13.982,7C790.885,341.869,791.042,341.941,791.224,342.028Z"
            transform="translate(0)"
            fill="currentColor"
          />
          <path
            d="M1081.047,540.477c-.952-.355-.862-1.622-.639-2.379a.891.891,0,0,0,0-.47,2.544,2.544,0,0,1,.072-1.914c.028-.056.064-.129.114-.148.647-.247.457-.8.449-1.265a1.172,1.172,0,0,1,.4-1.09,1.463,1.463,0,0,1,.726-.271c.76-.034,1.521-.017,2.282-.01a1.082,1.082,0,0,1,1.115.986,2.342,2.342,0,0,1-.188,1.555.3.3,0,0,0,.014.235,2.43,2.43,0,0,1,.008,2.018.376.376,0,0,0-.007.268,2.424,2.424,0,0,1,0,2.017.342.342,0,0,0,0,.265,2.455,2.455,0,0,1,.145,1.634,1.044,1.044,0,0,1-1.046.882c-.786.016-1.572.018-2.358,0a1.106,1.106,0,0,1-1.092-1.1C1081.037,541.3,1081.047,540.911,1081.047,540.477Zm1.86-4.53h-.912c-.994,0-.995,0-.974.99.009.443.12.553.573.555.748,0,1.5-.013,2.243,0,1.093.023,1.022-.06,1.014-1.07a.431.431,0,0,0-.5-.479C1083.87,535.951,1083.388,535.947,1082.907,535.947Zm.025,2.286h-.95c-.981,0-.987.006-.96,1,.011.424.121.541.547.544.76.006,1.521-.012,2.28,0,1.1.025,1.006-.1,1-1.085a.416.416,0,0,0-.474-.464C1083.894,538.234,1083.413,538.233,1082.932,538.233Zm.355,3.821h.57c1.015,0,1.038-.03,1-1.045-.014-.348-.173-.5-.521-.495-.519.01-1.038,0-1.556,0-1.017,0-1.033.021-1,1.04a.446.446,0,0,0,.52.5C1082.629,542.049,1082.958,542.054,1083.286,542.054Zm.049-6.849h.532c1,0,1.015-.015.984-1.02-.012-.394-.131-.518-.531-.524-.507-.007-1.014.009-1.52,0-1.066-.027-1.059.051-1.017,1.1a.405.405,0,0,0,.45.445C1082.6,535.207,1082.968,535.205,1083.336,535.205Z"
            transform="translate(-295.114 -184.554)"
            fill="currentColor"
          />
          <path
            d="M784.971,535.292a1.148,1.148,0,0,1,.756,1.24c-.039.42-.134.835-.2,1.253-.011.069-.032.159,0,.208a2.393,2.393,0,0,1,.186,1.556,1.06,1.06,0,0,1-.743.938c0,.416.007.807,0,1.2a1.106,1.106,0,0,1-1.093,1.136c-.786.019-1.573.017-2.358,0a1,1,0,0,1-.992-.7,2.571,2.571,0,0,1,.05-1.75.609.609,0,0,0,0-.4,2.721,2.721,0,0,1,0-1.89.567.567,0,0,0,.011-.36,2.6,2.6,0,0,1,.011-1.957.4.4,0,0,0,.012-.3,2.516,2.516,0,0,1-.119-1.708,1.04,1.04,0,0,1,1.032-.8c.773-.009,1.547-.015,2.321,0a1.112,1.112,0,0,1,1.124,1.146C784.978,534.485,784.971,534.864,784.971,535.292Zm-1.891,2.229h.95c.98,0,.99-.009.965-1-.01-.421-.126-.542-.545-.545-.76,0-1.52,0-2.28,0-1.02,0-1.041.026-1.008,1.038.011.345.164.512.513.509C782.143,537.517,782.611,537.521,783.08,537.521Zm.029.74h-.988c-.972,0-.973,0-.958.971.007.448.119.57.559.573.747.006,1.495-.011,2.242.005,1.133.025,1.028-.065,1.028-1.093a.412.412,0,0,0-.477-.458C784.047,538.265,783.578,538.261,783.109,538.261Zm-.413,2.285h-.531c-1.021,0-1.034.02-1,1.036a.448.448,0,0,0,.517.5c.519,0,1.037,0,1.556,0,1.016,0,1.059-.049,1-1.079-.018-.312-.164-.462-.474-.461Zm0-5.31v0c.19,0,.38,0,.569,0,1-.03,1-.031.96-1.037-.014-.386-.139-.5-.541-.507-.507,0-1.014,0-1.52,0-1.019,0-1.07.058-1.006,1.078.019.307.161.468.474.467Z"
            transform="translate(-18.087 -184.583)"
            fill="currentColor"
          />
        </g>
      </g>
    </Icon>
  );
}

export default BookHome;
