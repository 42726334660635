import { Icon, IconProps } from '@chakra-ui/react';

function Vaccines(props: IconProps): JSX.Element {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94 94" {...props}>
      <g transform="translate(-1204.999 -429)">
        <g transform="translate(1206.999 431)" fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="2">
          <circle cx="45" cy="45" r="45" stroke="none" />
          <circle cx="45" cy="45" r="46" fill="none" />
        </g>
        <g transform="translate(1227.428 452.78)">
          <path d="M201.979,351.841a1.527,1.527,0,0,1-2.479-.139c-.494-.57-1.045-1.09-1.5-1.566l-4.326,4.29c1.561,1.529,3.181,3.118,4.8,4.7.607.593,1.176,1.272.431,2.049-.807.841-1.512.254-2.132-.4-.6-.635-1.213-1.265-2.017-2.1a12.737,12.737,0,0,1-.942,1.235Q183.782,369.967,173.733,380c-1.661,1.661-1.7,1.664-3.4-.047-.527-.531-1.021-1.1-1.64-1.763-1.295,1.332-2.456,2.544-3.634,3.736-1.707,1.726-1.714,1.72-3.637.248-3.288,3.293-3.241,2.982-6.538,6.283-.238.238-.444.619-.722.682a9.033,9.033,0,0,1-1.7.09c.011-.568-.17-1.245.076-1.681a8.629,8.629,0,0,1,1.56-1.635c3-3,2.666-2.39,5.692-5.411-.138-.19-.223-.332-.332-.453-1.079-1.22-1.081-1.538.107-2.714,1.342-1.328,2.706-2.634,4.267-4.149-.789-.688-1.477-1.233-2.1-1.843-1.351-1.319-1.336-1.585.033-2.956q10.175-10.179,20.357-20.349a10.316,10.316,0,0,1,1.162-.852c-.849-.811-1.486-1.413-2.116-2.021s-1.261-1.308-.4-2.128c.784-.744,1.453-.189,2.047.423q2.374,2.442,4.759,4.874l4.274-4.411c-.432-.4-.964-.914-1.526-1.4a1.558,1.558,0,0,1-.217-2.567h1.011l10.864,10.864ZM184.761,349,163.85,369.915l8.315,8.316,2.077-2.1c-.951-.9-1.869-1.766-2.778-2.639-.57-.547-.907-1.19-.268-1.862.682-.714,1.362-.421,1.949.207.855.914,1.7,1.832,2.588,2.785l2.25-2.352c-.789-.742-1.6-1.478-2.377-2.243-.626-.614-1.481-1.285-.586-2.2.873-.9,1.584-.154,2.218.5.779.8,1.554,1.605,2.273,2.347l2.183-2.27c-.853-.816-1.768-1.683-2.674-2.562-.579-.562-.855-1.213-.179-1.855.655-.622,1.318-.372,1.873.214.864.911,1.71,1.839,2.63,2.832l2.159-2.37c-.815-.795-1.689-1.639-2.554-2.495-.567-.563-1.087-1.2-.342-1.94s1.453-.33,2.063.3c.836.865,1.668,1.734,2.441,2.539l2.313-2.188c-.882-.823-1.778-1.645-2.657-2.485-.575-.55-1.1-1.185-.384-1.94s1.438-.417,2.057.243l2.373,2.54,2.113-2.085Zm7.063,3.353,4.081-3.993-2.2-2.393-4.227,4.1Zm-26.41,22.694-3.8,3.729,1.354,1.43,3.885-3.633Z" transform="translate(-152.407 -339.96)" fill="currentColor" />
        </g>
      </g>
    </Icon>
  );
}

export default Vaccines;
