import { Text } from '@chakra-ui/react';
import JotformEmbed from 'react-jotform-embed';

import IComponentParams from 'Core/types/IComponentParams';

interface IJotform {
  url: string
}

export const JotformParams: IComponentParams[] = [
  {
    name: 'url',
    type: 'text',
    placeholder: 'Form URL',
    required: true,
  },
];

export const Jotform: React.FC<IJotform> = function ({ url }) {
  return url.includes('http') ? <JotformEmbed src={url} /> : <Text fontWeight="500">Waiting for URL</Text>;
};
