import { Image, Flex } from '@chakra-ui/react';
import React from 'react';

import useBuilder from 'Core/hooks/use-builder';

const Header: React.FC = function () {
  const { page } = useBuilder();

  const images: { [key: string]: string } = {
    About: '/AFLDS/headers/about-header.jpg',
    Business: '/AFLDS/headers/business-header.jpg',
    'Citizen Corps': '/AFLDS/headers/citizen-header.jpg',
    Community: '/AFLDS/headers/community-header.jpg',
    Covid: '/AFLDS/headers/covid-header.jpg',
    Events: '/AFLDS/headers/events-header.jpg',
    Health: '/AFLDS/headers/health-header.jpg',
    Legal: '/AFLDS/headers/legal-header.jpg',
    Library: '/AFLDS/headers/library-header.jpg',
    Medical: '/AFLDS/headers/medical-header.webp',
    default: '/AFLDS/headers/about-header.jpg',
  };

  return (
    <Flex
      w="100%"
      minH="160px"
      alignItems="center"
      p="32px"
      bgImage={images[page.section || 'default']}
      bgSize="cover"
    >
      <Image src="/AFLDS/logo.svg" h="80px" />
    </Flex>
  );
};

export default Header;
