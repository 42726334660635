import {
  Heading,
  Container,
  Box,
  useToast,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Avatar,
  Text,
  HStack,
  Skeleton,
  Stack,
  Grid,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { IoChevronDown } from 'react-icons/io5';

import ProjectsCarousel from 'Components/elements/ProjectsCarousel';
import Topbar from 'Components/layout/Topbar';
import api from 'Core/services/api';
import IPage from 'Core/types/IPage';
import type { UsersType } from 'Core/types/User';

const AdminHome: React.FC = function () {
  const [clients, setClients] = useState([]);
  const [activeClient, setActiveClient] = useState<UsersType>();
  const [loading, setLoading] = useState(true);
  const [allProjects, setAllProjects] = useState<IPage[]>([]);

  const toast = useToast();

  useEffect(() => {
    async function getData(): Promise<void> {
      try {
        const { data } = await api.get('/admin/projects');
        setClients(data.users);
        setActiveClient(data.users[0]);

        const allProjectsArr = [];

        for (let i = 0; i < data.users.length; i += 1) {
          for (let j = 0; j < data.users[i].sections.length; j += 1) {
            for (let k = 0; k < data.users[i].sections[j].items.length; k += 1) {
              allProjectsArr.push({
                ...data.users[i].sections[j].items[k],
                author: {
                  name: data.users[i].user.name,
                  picture: data.users[i].user.picture,
                },
              });
            }
          }
        }

        setAllProjects(allProjectsArr);

        setLoading(false);
      } catch (e) {
        toast({
          title: 'API Error.',
          description: 'Something went wrong.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    }
    getData();
  }, [toast]);

  return (
    <Box>
      <Topbar isAdmin />
      <Container maxW="container.xl">
        <Box my="32px">
          <Stack spacing="64px" mt="32px">
            <Box>
              <Heading as="h2" fontSize="24px" mb="16px">
                All projects sent to review
              </Heading>

              {
                loading ? (
                  <Grid gap="24px" templateColumns={{ base: '1fr', lg: '1fr 1fr 1fr 1fr' }}>
                    <Skeleton h="120px" borderRadius="8px" />
                    <Skeleton h="120px" borderRadius="8px" />
                    <Skeleton h="120px" borderRadius="8px" />
                    <Skeleton h="120px" borderRadius="8px" />
                  </Grid>
                ) : <ProjectsCarousel title="All sections" items={allProjects} />
              }

            </Box>

            <Box>
              <Flex alignItems="center" justifyContent="space-between">
                <Heading as="h2" fontSize="24px">
                  Client projects
                </Heading>

                {
                  loading
                    ? <Skeleton h="40px" w="250px" />
                    : (
                      <Menu>
                        <MenuButton
                          w="250px"
                          bgColor="white"
                          as={Button}
                          textAlign="left"
                          rightIcon={<IoChevronDown />}
                          overflow="hidden"
                          pr="20px"
                        >
                          <HStack>
                            <Avatar size="sm" src={activeClient.user.picture} name={activeClient.user.name} />
                            <Text>
                              {activeClient.user.name.length > 13
                                ? `${activeClient.user.name.substring(0, 13)}...` : activeClient.user.name}

                            </Text>
                          </HStack>
                        </MenuButton>
                        <MenuList>
                          {
                            clients.map((client) => (
                              <MenuItem
                                key={`option=${client.user.user_id}`}
                                onClick={() => setActiveClient(client)}
                              >
                                <HStack>
                                  <Avatar size="sm" src={client.user.picture} name={client.user.name} />
                                  <Text>{client.user.name}</Text>
                                </HStack>
                              </MenuItem>
                            ))
                          }
                        </MenuList>
                      </Menu>
                    )
                }
              </Flex>

              {
                !(loading && !activeClient)
                  ? activeClient.sections.map((section) => (
                    <Box mt="16px" key={section.name}>
                      <ProjectsCarousel title={section.name} items={section.items} />
                    </Box>
                  ))
                  : (
                    <Box mt="16px">
                      <Skeleton h="24px" w="100px" mb="16px" />
                      <Grid gap="24px" templateColumns={{ base: '1fr', lg: '1fr 1fr 1fr 1fr' }}>
                        <Skeleton h="120px" borderRadius="8px" />
                        <Skeleton h="120px" borderRadius="8px" />
                        <Skeleton h="120px" borderRadius="8px" />
                        <Skeleton h="120px" borderRadius="8px" />
                      </Grid>
                    </Box>
                  )
              }
            </Box>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};

export default AdminHome;
