import {
  Heading,
  Box,
  Text,
  Badge,
  Avatar,
  HStack,
} from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

import IProjectCard from 'Core/types/IProjectCard';
import moment from 'moment';

const ProjectCard: React.FC<IProjectCard> = function ({
  item,
}) {
  return (
    <Link to={`/builder/${item._id}`}>
      <Box
        mx="12px"
        p="24px"
        borderRadius="8px"
        bgColor="white"
        transition="0.3s"
        _hover={{
          opacity: 0.8,
          cursor: 'pointer',
        }}
        position="relative"
      // boxShadow="0px 20px 44px rgba(0,0,0,.05)"
      >
        <Heading
          mb="4px"
          as="h4"
          fontSize="16px"
          fontWeight="600"
        >
          {item.name}
        </Heading>
        <Text fontSize="12px">
          Updated at
          {' '}
          {moment(item.createdAt).format('MM-DD-YYYY')}
        </Text>

        {
          item.author
          && (
            <HStack mt="8px">
              <Avatar size="xs" src={item.author.picture} name={item.author.name} />
              <Text fontSize="14px">
                {
                  item.author.name.length > 15 ? `${item.author.name.substring(0, 15)}...` : item.author.name
                }

              </Text>
            </HStack>
          )
        }

        {
          item.published
          && (
            <Badge
              colorScheme="green"
              borderRadius="4px"
              fontSize="10px"
              position="absolute"
              top="8px"
              right="8px"
            >
              Sent to review
            </Badge>
          )
        }
      </Box>
    </Link>
  );
};

export default ProjectCard;
