import { Icon, IconProps } from '@chakra-ui/react';

function UltraOrthodox(props: IconProps): JSX.Element {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 55" {...props}>
      <g transform="translate(-1212 -1061)">
        <g transform="translate(1214 1063)" fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="2">
          <circle cx="25.5" cy="25.5" r="25.5" stroke="none" />
          <circle cx="25.5" cy="25.5" r="26.5" fill="none" />
        </g>
        <path
          d="M26.269,1.436a.567.567,0,0,0-.8,0L22.449,4.459l-.692-.692a2.117,2.117,0,0,0-2.926,0l-.406.406a2.114,2.114,0,0,0-2.67.211L7.422,12.717a2.046,2.046,0,0,0-.211,2.67L5.745,16.853a.567.567,0,0,0,0,.8l.835.835L4.3,20.764l-.835-.835a.567.567,0,0,0-.8,0L1.436,21.163a.567.567,0,0,0,0,.8l4.309,4.309a.563.563,0,0,0,.8,0l1.233-1.233a.567.567,0,0,0,0-.8L6.941,23.4,9.22,21.125l.835.835a.563.563,0,0,0,.8,0l1.467-1.467a2.046,2.046,0,0,0,2.67-.211l8.333-8.333a2.07,2.07,0,0,0,.211-2.67l.406-.406a2.074,2.074,0,0,0,0-2.926l-.692-.692,3.023-3.023A.567.567,0,0,0,26.269,1.436ZM16.552,5.181a.928.928,0,0,1,.662-.278.915.915,0,0,1,.662.278l.165.165h0l4.309,4.309h0l.165.165a.933.933,0,0,1,.241.5.439.439,0,0,0-.135.03l-5.174,2.3a2.008,2.008,0,0,1-2.211-.406,3.133,3.133,0,0,0-2.956-.82l-2.64.662ZM6.144,25.073,2.632,21.561l.436-.436.835.835L5.745,23.8l.835.835Zm0-2.467L5.1,21.561l2.279-2.279,1.045,1.045Zm4.309-1.843-.835-.835L7.776,18.087l-.835-.835,1.045-1.045L11.5,19.719Zm3.738-1.279a.968.968,0,0,1-1.331,0l-4.64-4.64a.928.928,0,0,1-.278-.662.917.917,0,0,1,.211-.564l4.422-1.106a1.99,1.99,0,0,1,1.88.519,3.132,3.132,0,0,0,2.211.91,3.188,3.188,0,0,0,1.256-.263l3.745-1.662Zm8.95-11.409-.384.384L19.245,4.948l.384-.384a.968.968,0,0,1,1.331,0l1.09,1.09h0l1.09,1.09a.935.935,0,0,1,0,1.331Z"
          transform="translate(1225.647 1074.646)"
          fill="currentColor"
        />
      </g>
    </Icon>
  );
}

export default UltraOrthodox;
