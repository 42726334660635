import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Stack,
  ModalFooter,
  Button,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

import ComponentParamInput from 'Components/elements/ComponentParamInput';
import useBuilder from 'Core/hooks/use-builder';
import useLine from 'Core/hooks/use-line';

const ComponentEditModal: React.FC = function () {
  const {
    updateComponent,
    updateIsOpen,
    updateOnClose,
    componentToUpdate,
    updating,
  } = useBuilder();
  const { line } = useLine();

  const [values, setValues] = useState<{
    [key: string]: string | boolean | React.ReactElement;
  }>(
    componentToUpdate?.data as {
      [key: string]: string | boolean | React.ReactElement;
    },
  );

  const updateValue = (
    name: string,
    value: string | boolean | React.ReactElement,
  ): void => {
    const valuesAux = values;
    valuesAux[name] = value;

    setValues(valuesAux);
  };

  const submit = async (): Promise<void> => {
    await updateComponent(line._id, componentToUpdate._id, values);
    updateOnClose();
  };

  useEffect(() => {
    setValues({});
  }, [updateIsOpen]);

  useEffect(() => {
    setValues(componentToUpdate?.data as { [key: string]: string });
  }, [updateIsOpen, componentToUpdate]);

  return (
    <Modal isOpen={updateIsOpen} onClose={updateOnClose}>
      <ModalOverlay />
      <ModalContent maxW={{ base: '100%', lg: '500px' }}>
        <ModalHeader>Component data</ModalHeader>
        <ModalCloseButton />
        <ModalBody maxH="70vh" overflowY="auto">
          <Stack spacing="16px">
            {componentToUpdate
              && componentToUpdate.params.map((param) => (
                <ComponentParamInput
                  param={param}
                  updateValue={updateValue}
                  defaultValue={values ? values[param.name] : ''}
                  key={param.name}
                />
              ))}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="gray" mr="auto" onClick={updateOnClose}>
            Cancel
          </Button>
          <Button colorScheme="blue" onClick={submit} isLoading={updating}>
            Save changes
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ComponentEditModal;
