import { Icon, IconProps } from '@chakra-ui/react';

function BishopStatement(props: IconProps): JSX.Element {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 56" {...props}>
      <g transform="translate(-1207 -1603)">
        <g transform="translate(1209 1605)" fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="2">
          <ellipse cx="25.5" cy="26" rx="25.5" ry="26" stroke="none" />
          <ellipse cx="25.5" cy="26" rx="26.5" ry="27" fill="none" />
        </g>
        <g transform="translate(1223.88 1618.913)">
          <path
            d="M74,40.323V60.34a.358.358,0,0,0,.343.359h1.591V62.29a.358.358,0,0,0,.359.343h1.632V64.24a.31.31,0,0,0,.318.327H94.36a.331.331,0,0,0,.343-.327V44.223a.358.358,0,0,0-.343-.359H92.769V42.273a.358.358,0,0,0-.359-.343H90.778V40.323A.31.31,0,0,0,90.46,40H74.343a.331.331,0,0,0-.343.327ZM92.769,62.29V44.546H94.02V63.884H78.607V62.633h13.8A.358.358,0,0,0,92.769,62.29Zm-1.991-1.95V42.613h1.308V61.951H76.616V60.7H90.46A.338.338,0,0,0,90.778,60.34Zm-16.1-19.661H90.1V60.017H74.682Z"
            transform="translate(-74 -39.996)"
            fill="currentColor"
          />
          <path
            d="M120.87,127.738H108.652a.341.341,0,0,0,0,.683H120.87a.341.341,0,0,0,0-.683Zm0-1.934H108.652a.341.341,0,1,0,0,.683H120.87a.341.341,0,0,0,0-.683Zm-12.218-1.251h4.419a.341.341,0,0,0,0-.683c-4.608,0-4.761.153-4.761.341A.341.341,0,0,0,108.652,124.553Z"
            transform="translate(-106.36 -109.709)"
            fill="currentColor"
          />
        </g>
      </g>
    </Icon>
  );
}

export default BishopStatement;
