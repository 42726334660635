import {
  Box,
  Stack,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import AddLineBox from 'Components/elements/AddLineBox';
import ComponentConfigModal from 'Components/elements/ComponentConfigModal';
import ComponentEditModal from 'Components/elements/ComponentEditModal';
import Header from 'Components/elements/Header';
import Line from 'Components/elements/Line';
import ComponentConfigProvider from 'Core/context/ComponentConfigProvider';
import LineProvider from 'Core/context/LineProvider';
import useBuilder from 'Core/hooks/use-builder';
import api from 'Core/services/api';

const Builder: React.FC = function () {
  const {
    lines, setPage, setLines, setIsVisualization,
  } = useBuilder();

  const { id } = useParams();

  const toast = useToast();

  useEffect(() => {
    setIsVisualization(true);
  }, [setIsVisualization]);

  useEffect(() => {
    async function getData(): Promise<void> {
      try {
        const { data } = await api.get(`/pagebuilder/project/${id}`);

        setPage(data);
        setLines(data.sections);
      } catch (e) {
        toast({
          title: 'API Error.',
          description: 'Something went wrong.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    }
    if (id) getData();
  }, [id, toast, setPage, setLines]);

  return (
    <ComponentConfigProvider>
      <Box>
        <Stack p="24px" spacing="16px">
          <Header />
          {lines.map((line) => (
            <LineProvider line={line} key={line._id}>
              <Line />
              <ComponentConfigModal />
              <ComponentEditModal />
            </LineProvider>
          ))}
          <AddLineBox />
        </Stack>
      </Box>
    </ComponentConfigProvider>
  );
};

export default Builder;
