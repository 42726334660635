import { useAuth0 } from '@auth0/auth0-react';
import {
  Box,
  Heading,
  HStack,
  Button,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Avatar,
  MenuDivider,
} from '@chakra-ui/react';
import React, { useState } from 'react';
// import { ImUndo, ImRedo } from 'react-icons/im';
import { FaUser } from 'react-icons/fa';
import { FiSave } from 'react-icons/fi';
import { MdLogout } from 'react-icons/md';
import { useParams, Link } from 'react-router-dom';

import NewProjectForm from 'Components/elements/NewProjectForm';
import useBuilder from 'Core/hooks/use-builder';

const Topbar: React.FC<{ isAdmin?: boolean }> = function ({ isAdmin }) {
  const {
    logout, user,
  } = useAuth0();

  const { id } = useParams();
  const {
    deleteProjectOnOpen, setDeleteId, page, sendToReview, isVisualization,
  } = useBuilder();

  const handleDelete = (): void => {
    deleteProjectOnOpen();
    setDeleteId(id);
  };

  const [sendingReview, setSendingReview] = useState(false);
  const handleSendReview = async (): Promise<void> => {
    setSendingReview(true);
    await sendToReview();
    setSendingReview(false);
  };

  return (
    <Flex
      px="24px"
      py="16px"
      bgColor="white"
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <HStack spacing="16px">
        <Link to="/home">
          <Heading as="h1" fontSize="25px">
            Page
            <Text d="inline" color="blue.500">Builder</Text>
          </Heading>
        </Link>
        <Box w="2px" h="37px" bgColor="gray.50" />
        <Heading as="h2" fontSize="20px" fontWeight={500}>
          {!!id && `${page.section} - ${page.name}`}
        </Heading>
      </HStack>

      <HStack spacing="16px">
        {/* <HStack spacing="8px">
          <IconButton
            color="blue.500"
            aria-label="undo"
            icon={<ImUndo size="15px" />}
            bgColor="white"
            _hover={{
              bgColor: 'gray.100'
            }}
            boxShadow="0 3px 10px rgba(184, 197, 255, .45)"
          />
          <IconButton
            color="blue.500"
            aria-label="redo"
            icon={<ImRedo size="15px" />}
            bgColor="white"
            _hover={{
              bgColor: 'gray.100'
            }}
            boxShadow="0 3px 10px rgba(184, 197, 255, .45)"
          />
        </HStack> */}

        {/* <Box w="2px" h="37px" bgColor="gray.50" /> */}

        {
          (!isVisualization && !isAdmin)
          && (
            id
              ? (
                <>
                  <Button
                    leftIcon={<FiSave size="20px" />}
                    colorScheme="red"
                    boxShadow="0 3px 10px rgba(184, 197, 255, .45)"
                    onClick={handleDelete}
                    disabled={isVisualization}
                  >
                    Delete project
                  </Button>

                  <Button
                    colorScheme={page.published ? 'green' : 'blue'}
                    boxShadow="0 3px 10px rgba(184, 197, 255, .45)"
                    disabled={isVisualization}
                    onClick={handleSendReview}
                    isLoading={sendingReview}
                  >
                    {page.published ? 'Sent to review' : 'Send to review'}
                  </Button>
                </>
              )
              : <NewProjectForm />
          )
        }

        <Menu>
          <MenuButton
            variant="ghost"
            as={Button}
          >
            <Avatar
              w="30px"
              h="30px"
              name={user.name}
              src={user.picture}
            />
          </MenuButton>
          <MenuList fontSize="14px">
            <HStack px="16px">
              <Avatar
                w="20px"
                h="20px"
                name={user.name}
                src={user.picture}
              />
              <Text fontWeight="600">{user.name}</Text>
            </HStack>
            <MenuDivider />
            <MenuItem icon={<FaUser />} fontWeight="500">My Profile</MenuItem>
            <MenuItem
              onClick={() => logout({ returnTo: window.location.origin })}
              icon={<MdLogout />}
              fontWeight="500"
            >
              Logout
            </MenuItem>
          </MenuList>
        </Menu>
      </HStack>
    </Flex>
  );
};

Topbar.defaultProps = {
  isAdmin: false,
};

export default Topbar;
