const dateFormatter = (date, dot = false, numberFormat = false) => {
  const dateInTimezone =
    new Date(date).getTime() + new Date().getTimezoneOffset() * 60000;
  const dateInNumbers = new Date(dateInTimezone);

  if (numberFormat) {
    const day = dateInNumbers.getDate();
    const month = dateInNumbers.getMonth() + 1;
    const year = dateInNumbers.getFullYear();
    return `${month < 10 ? `0${month}` : month}/${
      day < 10 ? `0${day}` : day
    }/${year}`;
  }

  const dateString = new Date(dateInTimezone).toDateString();
  const dateArray = dateString.split(" ").slice(1).join(" ").split(" ");
  return `${dateArray[0]}${dot ? "." : ""} ${dateArray[1]}, ${dateArray[2]}`;
};

export default dateFormatter;
