import {
  Flex,
  Heading,
  Image,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react';

import PDFIcon from 'assets/icons/pdf-icon.svg';
import PlaintiffIcon from 'assets/icons/plaintiff-icon.svg';
import PressReleaseIcon from 'assets/icons/press-release_icon.svg';
import IComponentParams from 'Core/types/IComponentParams';

import Button from '../Button';

export const DocumentCardParams: IComponentParams[] = [
  { name: 'title', type: 'text', required: true },
  { name: 'highlightedTitle', placeholder: 'Your', type: 'text' },
  { name: 'content', placeholder: 'Your content', type: 'textarea' },
  {
    name: 'type',
    type: 'select',
    options: [
      { label: 'PDF', value: 'pdf' },
      { label: 'Press Release', value: 'press_release' },
      { label: 'Plaintiff', value: 'plaintiff' },
    ],
    required: true,
  },
  {
    name: 'url', placeholder: '', type: 'text', required: true,
  },
  { name: 'download', type: 'text' },
];

interface IDocumentCard {
  title: string
  highlightedTitle: string
  content: string
  type: string
  url: string,
  download: string
}

export function DocumentCard({
  title, highlightedTitle, content, type, url, download,
}: IDocumentCard): JSX.Element {
  const file = {
    pdf: PDFIcon,
    press_release: PressReleaseIcon,
    plaintiff: PlaintiffIcon,
  };

  return (
    <Flex
      maxW="100%"
      direction={{ base: 'column', lg: 'row' }}
      gap={{ base: '-70px', lg: '-32px' }}
      alignItems={{
        base: 'center',
        lg: 'center',
      }}
    >
      <Image
        src={file[type] || file.pdf}
        width="250px"
        position="relative"
        zIndex="10"
        alt="preview card image"
      />
      <Stack
        justifyContent="center"
        bg="white"
        py="50px"
        borderRadius="5px"
        boxShadow="0px 10px 20px rgba(0,0,0,.04)"
        pl="60px"
        pr="50px"
        width="100%"
        textAlign={{ base: 'center', lg: 'left' }}
      >
        <Heading wordBreak="break-word" as="h2" color="secondary.500" fontSize="22px" mb="16px">
          <Text display="inline" color="primary.500" mr="6px">
            {highlightedTitle}
          </Text>
          {title}
        </Heading>
        <Text wordBreak="break-word" mb="16px">{content}</Text>
        <Flex
          direction="row"
          justifyContent={{ base: 'center', lg: 'flex-start' }}
          gap="18px"
        >
          <Link
            target="_blank"
            href={url}
            _hover={{ textDecor: 'none' }}
          >
            <Button colorScheme="secondary" variant="outlined" whiteSpace="nowrap" withIcon>
              See more
            </Button>
          </Link>
          {download && (
            <Link
              href={download}
              _hover={{ textDecor: 'none' }}
              download
            >
              <Button colorScheme="primary" variant="outlined" withIcon>
                Download
              </Button>
            </Link>
          )}
        </Flex>
      </Stack>
    </Flex>
  );
}

export default DocumentCard;
