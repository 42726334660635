/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, {
  createContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import ILine from 'Core/types/ILine';
import ILineContext, { DEFAULT_VALUES } from 'Core/types/ILineContext';

export const LineContext = createContext<ILineContext>(DEFAULT_VALUES);

const LineProvider: React.FC<ILineContext> = function ({
  line: lineProps,
  children,
}) {
  const [line, setLine] = useState<ILine>(lineProps);

  useEffect(() => {
    setLine(lineProps);
  }, [lineProps]);

  const memoizedValues = useMemo(() => ({ line }), [line]);

  return (
    <LineContext.Provider value={memoizedValues}>
      {children}
    </LineContext.Provider>
  );
};

export default LineProvider;
