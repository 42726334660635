import {
  Grid,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Flex,
  Text,
  ModalBody,
  ModalCloseButton,
  HStack,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { IoAddCircleOutline } from 'react-icons/io5';

import components from 'Components/aflds';
import ComponentButton from 'Components/elements/ComponentButton';
import useComponentConfig from 'Core/hooks/use-component-config';

const ComponentsModal: React.FC<{ column: number }> = function ({ column }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { setActiveColumn } = useComponentConfig();

  const open = (): void => {
    setActiveColumn(column);
    onOpen();
  };

  return (
    <>
      {' '}
      <Flex
        p="24px"
        border="2px dashed"
        borderColor="gray.200"
        h="100px"
        alignItems="center"
        justifyContent="center"
        borderRadius="10px"
        color="gray.400"
        fontWeight="600"
        cursor="pointer"
        onClick={open}
        transition="0.3s"
        _hover={{
          borderColor: 'gray.400',
          color: 'gray.600',
        }}
      >
        <HStack spacing="8px">
          <Text>ADD COMPONENT</Text>
          <IoAddCircleOutline size="30px" />
        </HStack>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW={{ base: '100%', lg: '600px' }}>
          <ModalHeader>Add component</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Select
              variant="outline"
              borderWidth="2px"
              fontWeight="500"
              color="blue.500"
              borderColor="blue.500"
              borderRadius="10px"
              mt="24px"
              cursor="pointer"
              bg="white"
              _hover={{
                bg: 'gray.50',
              }}
            >
              <option>Legal</option>
            </Select>
            <Grid
              templateColumns={{ base: '1fr 1fr', lg: '1fr 1fr 1fr 1fr' }}
              gap="24px"
              mt="32px"
              mb="32px"
            >
              {components.map((component) => (
                <ComponentButton component={component} key={component.name} />
              ))}
            </Grid>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ComponentsModal;
