/* eslint-disable no-nested-ternary */
import { Flex, Spinner } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

import api from 'Core/services/api';

import AdminHome from './admin/AdminHome';
import UserHome from './UserHome';

const Home: React.FC = function () {
  const [roles, setRoles] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getData(): Promise<void> {
      try {
        const { data } = await api.get('/users/roles');
        setRoles(data.roles);
        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    }
    getData();
  }, []);

  return (
    loading
      ? (
        <Flex
          h="100vh"
          w="100%"
          alignItems="center"
          justifyContent="center"
        >
          <Spinner />
        </Flex>
      )
      : (roles.includes('admin') ? <AdminHome /> : <UserHome />)
  );
};

export default Home;
