import { Icon, IconProps } from '@chakra-ui/react';

function Chat(props: IconProps): JSX.Element {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 57 57" {...props}>
      <g transform="translate(-482 -446)">
        <g transform="translate(484 448)" fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="2">
          <circle cx="26.5" cy="26.5" r="26.5" stroke="none" />
          <circle cx="26.5" cy="26.5" r="27.5" fill="none" />
        </g>
        <g transform="translate(497.5 461.5)">
          <rect
            width="26"
            height="26"
            transform="translate(0.5 0.5)"
            fill="none"
          />
          <path
            d="M36.652,70.7,33.331,73.5A.809.809,0,0,1,32,72.876V56.81A.809.809,0,0,1,32.81,56h17.8a.809.809,0,0,1,.809.809V69.758a.809.809,0,0,1-.809.809H37.025Z"
            transform="translate(-28.826 -50.398)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <line
            x2="7"
            transform="translate(9.5 11.5)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <line
            x2="7"
            transform="translate(9.5 14.5)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
        </g>
      </g>
    </Icon>
  );
}

export default Chat;
