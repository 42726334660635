import {
  Flex,
  Heading,
  Text,
  Link,
} from '@chakra-ui/react';

import IComponentParams from 'Core/types/IComponentParams';
import Date from 'Core/util/date';

interface IMinimalistCard {
  url: string
  title: string
  date: string | Date
}

export const MinimalistCardParams: IComponentParams[] = [
  {
    name: 'url',
    type: 'text',
    required: true,
  },
  {
    name: 'title',
    type: 'text',
    placeholder: 'Your Title',
    required: true,
  },
  {
    name: 'date',
    type: 'date',
    placeholder: `${Date.today}`,
    required: true,
  },
];

export function MinimalistCard({ url, title, date = Date.today }: IMinimalistCard): JSX.Element {
  return (
    <Flex
      flexShrink="0"
      href={`/news/press-releases/${url}`}
      p="24px"
      border="2px solid"
      borderColor="secondary.500"
      borderRadius="5px"
      bg="#f7f7f7"
    >
      <Flex
        flexDir="column"
        alignItems="flex-start"
        justifyContent="space-between"
        css={{ gap: '24px' }}
      >
        <Flex flexDir="column" css={{ gap: '6px' }}>
          <Text fontSize="16px" color="primary.500" fontWeight="bold">
            {Date.format(date, '{mmm} {dd}, {yyyy}')}
          </Text>
          <Heading wordBreak="break-word" fontSize="20px" fontWeight="normal">
            {title}
          </Heading>
        </Flex>
        <Link
          color="primary.500"
          fontSize="16px"
          fontWeight="bold"
          textTransform="uppercase"
          _hover={{ textDecor: 'none' }}
          href={url}
        >
          Read More
        </Link>
      </Flex>
    </Flex>
  );
}
