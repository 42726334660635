import { Icon, IconProps } from '@chakra-ui/react';

function Video(props: IconProps): JSX.Element {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 58" {...props}>
      <g transform="translate(-466.758 -1582.758)">
        <g transform="translate(468.758 1584.758)" fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="2">
          <circle cx="27" cy="27" r="27" stroke="none" />
          <circle cx="27" cy="27" r="28" fill="none" />
        </g>
        <g transform="translate(481.964 1603.232)">
          <path
            d="M26.751,10.5H6.057A3.563,3.563,0,0,0,2.5,14.057V24.4A3.563,3.563,0,0,0,6.057,27.96H26.751A3.563,3.563,0,0,0,30.307,24.4V14.057A3.563,3.563,0,0,0,26.751,10.5Zm1.617,7.437V24.4a1.617,1.617,0,0,1-1.617,1.617H6.057A1.617,1.617,0,0,1,4.44,24.4V14.057A1.617,1.617,0,0,1,6.057,12.44H26.751a1.617,1.617,0,0,1,1.617,1.617Z"
            transform="translate(-2.5 -10.5)"
            fill="currentColor"
          />
          <path
            d="M27.664,20.484,19.9,16.6a.972.972,0,0,0-1.4.867v7.76a.982.982,0,0,0,.459.828.953.953,0,0,0,.511.142.93.93,0,0,0,.433-.1l7.76-3.88a.968.968,0,0,0,0-1.733ZM20.44,23.66V19.036l4.624,2.315Z"
            transform="translate(-8.153 -12.621)"
            fill="currentColor"
          />
        </g>
      </g>
    </Icon>
  );
}

export default Video;
