import {
  Heading,
  Box,
  Flex,
  IconButton,
  HStack,
} from '@chakra-ui/react';
import React, { useRef } from 'react';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import Slider from 'react-slick';

import ProjectCard from 'Components/elements/ProjectCard';
import IProjectsCarousel from 'Core/types/IProjectsCarousel';

const ProjectsCarousel: React.FC<IProjectsCarousel> = function ({
  title,
  items,
}) {
  const sliderRef = useRef<Slider>(null);

  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
  };

  return (
    <Box>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        mb="16px"
      >
        {
          title
          && (
            <Heading
              as="h3"
              size="md"
              fontWeight="500"
            >
              {title}
            </Heading>
          )
        }

        <HStack spacing="8px">
          <IconButton
            size="sm"
            icon={<BsChevronLeft />}
            aria-label="back"
            colorScheme="blue"
            onClick={() => sliderRef.current?.slickPrev()}
          />
          <IconButton
            size="sm"
            icon={<BsChevronRight />}
            aria-label="forward"
            colorScheme="blue"
            onClick={() => sliderRef.current?.slickNext()}
          />
        </HStack>
      </Flex>

      <Box mx="-12px">
        <Slider {...settings} ref={sliderRef}>
          {
            items.map((item) => (
              <ProjectCard
                key={item._id}
                item={item}
              />
            ))
          }
          {
            items.length < 5
            && Array.from({ length: 5 - items.length }).map((item) => <Box key={`placeholder-${item}`} />)
          }
        </Slider>
      </Box>
    </Box>
  );
};

export default ProjectsCarousel;
