import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from '@chakra-ui/react';
import React from 'react';

interface IItem {
  title: string;
}

export const Item: React.FC<IItem> = function ({ title, children }) {
  return (
    <AccordionItem
      border="none"
      bg="white"
      borderRadius="5px"
      boxShadow="0px 10px 20px rgba(0,0,0,.04)"
    >
      <h2>
        <AccordionButton p="30px" _hover={{ bg: 'white' }}>
          <Box wordBreak="break-word" flex="1" textAlign="left" fontWeight="700">
            {title}
          </Box>
          <AccordionIcon color="primary.500" />
        </AccordionButton>
      </h2>
      <AccordionPanel p="30px" color="#828282" fontSize="16px">
        {children}
      </AccordionPanel>
    </AccordionItem>
  );
};

export default Item;
